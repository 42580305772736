import React, { useLayoutEffect } from "react";
import merge from "lodash/merge";
import { defaultTheme } from "react-admin";
import {
    createTheme as createMuiTheme,
    makeStyles as makeMaterialStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import typography from "./Typography";
import { useAppContext } from "../appProvider";

export const PortalThemeSettings = merge({}, defaultTheme, {
    typography: typography,
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1024,
            xl: 1600,
        },
    },
    palette: {
        primary: {
            main: "#C8162F",
        },
        secondary: {
            main: "#2B3B46",
            dark: "#202C34",
        },
        background: {
            main: "#F2F3F4",
        },
    },
    mixins: {
        toolbar: {
            minHeight: 80,
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiCard: {
            elevation: 6,
        },
        MuiTextField: {
            variant: "outlined",
        },
    },
    // components: {
    //     PortalAuthTitle: {
    //         color: "red",
    //     },
    //     PortalButtonLink: {
    //         display: "inline-flex",
    //         padding: "6px 16px",
    //         fontSize: "1rem",
    //         minWidth: "64px",
    //         boxSizing: "border-box",
    //         transition:
    //             "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //         fontWeight: 500,
    //         lineHeight: 1.75,
    //         borderRadius: "4px",
    //         textDecoration: "none",
    //     },
    // },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: "1rem",
            },
        },
        MuiSvgIcon: {
            root: {
                fill: "rgba(0, 0, 0, 0.87)",
            },
        },
        MuiFormControl: {
            root: {},
        },
        MuiPaper: {
            root: {
                backgroundColor: "#fff",
            },
        },
        MuiSkeleton: {
            root: {
                backgroundColor: "red",
            },
            square: {
                backgroundColor: "red",
            },
        },
        MuiButton: {
            root: {
                fontSize: "1rem",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: "40px",
            },
        },
        MuiSelect: {
            icon: {
                color: "rgba(0, 0, 0, 0.87)",
                top: "unset",
                marginLeft: ".25rem",
            },
            select: {
                "&:focus": {
                    borderRadius: "4px",
                    backgroundColor: "#e0e0e0",
                },
            },
        },
        MuiCard: {
            root: {
                borderRadius: ".75rem",
            },
        },
        MuiCardHeader: {
            root: {
                padding: "2rem 2.25rem",
            },
            action: {
                marginTop: 0,
            },
        },
        MuiCardContent: {
            root: {
                padding: "2rem 2.25rem",
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: "#F8F8F8",
            },
        },
        MuiTablePagination: {
            spacer: {
                display: "none",
            },
            toolbar: {
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "2.25rem",
                paddingRight: "2.25rem",
            },
            select: {
                paddingTop: "6px !important",
                paddingRight: "35px !important",
                paddingBottom: "6px !important",
                paddingLeft: "10px !important",
                fontSize: "1rem",
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                lineHeight: "1.75",
                borderRadius: "4px",
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.87)",
                backgroundColor: "#e0e0e0",
            },
            selectRoot: {
                padding: "0 !important",
            },
        },
        MuiTableCell: {
            root: {
                "&:first-child": {
                    paddingLeft: "2.25rem",
                },
                "&:last-child": {
                    paddingRight: "2.25rem",
                },
            },
            head: {
                borderBottom: 0,
            },
            paddingCheckbox: {
                "&:first-child": {
                    paddingLeft: "2.25rem",
                },
                "&:last-child": {
                    paddingRight: "2.25rem",
                },
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "#F8F8F8",
            },
        },
        // RaMenuItemLink: {
        //     root: {
        //         color: "rgba(0, 0, 0, 0.87)",
        //     },
        // },
        RaPlaceholder: {
            root: {
                borderRadius: 4,
            },
        },
        RaPaginationActions: {
            actions: {
                marginLeft: "auto",
            },
        },
        RaListToolbar: {
            toolbar: {
                justifyContent: "flex-end",
            },
        },
        RaDatagrid: {
            headerCell: {
                backgroundColor: "#F8F8F8",
            },
        },
        RaSidebar: {
            root: {
                height: "100vh",
            },
            fixed: {
                height: "100vh",
            },
        },
        RaEdit: {
            noActions: {
                marginTop: 0,
            },
        },
        RaCreate: {
            noActions: {
                marginTop: 0,

                "@media (min-width: 480px)": {
                    marginTop: 0,
                },
            },
        },
    },
    sidebar: {
        width: 268,
        closedWidth: 85,
    },
    appbar: {
        height: "80px",
    },
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.05),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.03)",
        "0px 3px 1px -2px rgba(0,0,0,0.05),0px 2px 2px 0px rgba(0,0,0,0.04),0px 1px 5px 0px rgba(0,0,0,0.03)",
        "0px 3px 3px -2px rgba(0,0,0,0.05),0px 3px 4px 0px rgba(0,0,0,0.04),0px 1px 8px 0px rgba(0,0,0,0.03)",
        "0px 2px 4px -1px rgba(0,0,0,0.05),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.03)",
        "0px 3px 5px -1px rgba(0,0,0,0.05),0px 5px 8px 0px rgba(0,0,0,0.04),0px 1px 14px 0px rgba(0,0,0,0.03)",
        "0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.04),0px 1px 18px 0px rgba(0,0,0,0.03)",
        "0px 4px 5px -2px rgba(0,0,0,0.05),0px 7px 10px 1px rgba(0,0,0,0.04),0px 2px 16px 1px rgba(0,0,0,0.03)",
        "0px 5px 5px -3px rgba(0,0,0,0.05),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.03)",
        "0px 5px 6px -3px rgba(0,0,0,0.05),0px 9px 12px 1px rgba(0,0,0,0.04),0px 3px 16px 2px rgba(0,0,0,0.03)",
        "0px 6px 6px -3px rgba(0,0,0,0.05),0px 10px 14px 1px rgba(0,0,0,0.04),0px 4px 18px 3px rgba(0,0,0,0.03)",
        "0px 6px 7px -4px rgba(0,0,0,0.05),0px 11px 15px 1px rgba(0,0,0,0.04),0px 4px 20px 3px rgba(0,0,0,0.03)",
        "0px 7px 8px -4px rgba(0,0,0,0.05),0px 12px 17px 2px rgba(0,0,0,0.04),0px 5px 22px 4px rgba(0,0,0,0.03)",
        "0px 7px 8px -4px rgba(0,0,0,0.05),0px 13px 19px 2px rgba(0,0,0,0.04),0px 5px 24px 4px rgba(0,0,0,0.03)",
        "0px 7px 9px -4px rgba(0,0,0,0.05),0px 14px 21px 2px rgba(0,0,0,0.04),0px 5px 26px 4px rgba(0,0,0,0.03)",
        "0px 8px 9px -5px rgba(0,0,0,0.05),0px 15px 22px 2px rgba(0,0,0,0.04),0px 6px 28px 5px rgba(0,0,0,0.03)",
        "0px 8px 10px -5px rgba(0,0,0,0.05),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.03)",
        "0px 8px 11px -5px rgba(0,0,0,0.05),0px 17px 26px 2px rgba(0,0,0,0.04),0px 6px 32px 5px rgba(0,0,0,0.03)",
        "0px 9px 11px -5px rgba(0,0,0,0.05),0px 18px 28px 2px rgba(0,0,0,0.04),0px 7px 34px 6px rgba(0,0,0,0.03)",
        "0px 9px 12px -6px rgba(0,0,0,0.05),0px 19px 29px 2px rgba(0,0,0,0.04),0px 7px 36px 6px rgba(0,0,0,0.03)",
        "0px 10px 13px -6px rgba(0,0,0,0.05),0px 20px 31px 3px rgba(0,0,0,0.04),0px 8px 38px 7px rgba(0,0,0,0.03)",
        "0px 10px 13px -6px rgba(0,0,0,0.05),0px 21px 33px 3px rgba(0,0,0,0.04),0px 8px 40px 7px rgba(0,0,0,0.03)",
        "0px 10px 14px -6px rgba(0,0,0,0.05),0px 22px 35px 3px rgba(0,0,0,0.04),0px 8px 42px 7px rgba(0,0,0,0.03)",
        "0px 11px 14px -7px rgba(0,0,0,0.05),0px 23px 36px 3px rgba(0,0,0,0.04),0px 9px 44px 8px rgba(0,0,0,0.03)",
        "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.03)",
    ],
});

export const createTheme = (theme = {}) => {
    const mergedTheme = merge({}, PortalThemeSettings, theme);
    return createMuiTheme(mergedTheme);
};

export const makeStyles = (props, options, theme = PortalThemeSettings) => {
    return makeMaterialStyles(props, {
        defaultTheme: createMuiTheme(theme),
        ...options,
    });
};

function cssVars(theme) {
    const vars = {};

    const iterate = (obj, callback, path = []) => {
        Object.keys(obj).forEach((key) => {
            const currentPath = [...path, key];
            callback(currentPath, obj[key]);

            if (typeof obj[key] === "object")
                iterate(obj[key], callback, currentPath);
        });
    };

    iterate(theme, (path, value) => {
        const valueType = typeof value;
        if (valueType !== "number" && valueType !== "string") return;

        const key = path.reduce((acc, val) => acc + "-" + val, "-");
        if (!key.match(/^([A-Za-z0-9\-_]+)$/)) return;

        vars[key] = value;
    });

    return vars;
}

export const PortalThemeProvider = ({ children, options = {} }) => {
    const { siteSettings: settings } = useAppContext();

    useLayoutEffect(() => {
        const variables = cssVars({
            ...theme,
            spacing: theme.spacing(1) + "px",
        });

        const root = document.documentElement;
        Object.keys(variables).forEach((key) =>
            root.style.setProperty(key, variables[key])
        );
    }, []);

    const theme = createTheme(
        settings ? merge({}, options, settings.theme) : options
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default PortalThemeProvider;
