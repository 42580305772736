import VideoCover from "./VideoCover";
import { makeStyles } from "../Theme/PortalThemeProvider";

const useStyles = makeStyles((theme) => ({
    asideOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.75,
    },
    asideBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
}));

const Aside = ({ image, video }) => {
    const classes = useStyles();

    return (
        <>
            {image && (
                <img
                    alt="Background"
                    src={image}
                    className={classes.asideBackground}
                />
            )}
            {video && <VideoCover src={video} />}
            <div className={classes.asideOverlay}></div>
        </>
    );
};

export default Aside;
