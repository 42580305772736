import { FunctionField, useRecordContext, useTranslate } from "react-admin";
import get from "lodash/get";

const FormattedWeightField = (props) => {
    const { className, source, ...rest } = props;
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
    const value = get(record, source);
    return (
        <FunctionField
            className={className}
            {...rest}
            render={(record) => `${value} kg`}
        />
    );
};

export default FormattedWeightField;
