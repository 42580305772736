import axios from "axios";
import Cookies from "js-cookie";

const PROVIDER_URI = process.env.REACT_APP_DATA_PROVIDER_URI || null;

export default {
    async login({ domain, portal, email, password }) {
        // const corsRequest = new Request(process.env.REACT_APP_AUTH_CORS_URI, {
        //     method: 'GET',
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });
        // const loginRequest = new Request(process.env.REACT_APP_AUTH_LOGIN_URI, {
        //     method: 'POST',
        //     body: JSON.stringify({ username, password }),
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });

        try {
            const corsResponse = await axios.get(
                PROVIDER_URI + `/auth/csrf-cookie`
            );
            if (corsResponse.status < 200 || corsResponse.status >= 300)
                throw new Error(corsResponse.statusText);

            const response = await axios.post(PROVIDER_URI + `/auth/login`, {
                domain,
                portal,
                email,
                password,
            });
            if (response.status < 200 || response.status >= 300)
                throw new Error(response.statusText);

            const auth = response.data;
            Cookies.set("portal_auth", JSON.stringify(auth));
            Cookies.set("portal_portal", portal);
        } catch (e) {
            throw new Error(
                e.response?.data?.errors?.message ||
                    "Kan niet inloggen, probeer het later opnieuw."
            );
        }
    },
    logout() {
        Cookies.remove("portal_auth");
        return Promise.resolve();
    },
    checkError(errorProps) {
        const { response: error } = errorProps;

        if (error.status === 401 || error.status === 403) {
            Cookies.remove("portal_auth");
            return Promise.resolve("guest");
        }
        return Promise.resolve("guest");
    },
    checkAuth() {
        return Cookies.get("portal_auth")
            ? Promise.resolve()
            : Promise.reject();
    },
    async getPermissions() {
        const cookieData = Cookies.get("portal_auth");
        const data = cookieData ? JSON.parse(cookieData) : null;
        if (!(data && data.token)) return Promise.resolve("guest");

        try {
            const response = await axios.get(
                PROVIDER_URI + `/auth/permissions`,
                {
                    headers: { "X-Authorization": `Bearer ${data.token}` },
                }
            );
            return Promise.resolve(response.data.permissions);
        } catch (e) {
            return this.checkError(e);
        }
    },
    getIdentity: () => {
        try {
            const cookieData = Cookies.get("portal_auth");
            const data = cookieData ? JSON.parse(cookieData) : null;
            return data
                ? Promise.resolve({
                      email: data.email,
                      full_name: data.full_name,
                      first_name: data.first_name,
                      last_name: data.last_name,
                  })
                : Promise.reject();
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
