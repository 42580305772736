import React, { useState } from "react";
import { useTranslate, useLogin, useRedirect } from "react-admin";
import { Field, Form } from "react-final-form";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AuthLayout from "../Layout/AuthLayout";
import { useAppContext } from "../appProvider";
import Aside from "../Components/Aside";
import InputField from "../Components/Fields/InputField";
import SelectField from "../Components/Fields/SelectField";
import Cookies from "js-cookie";

const APP_MODE = process?.env?.NODE_ENV;

const useStyles = makeStyles((theme) => {
    return {
        select: {
            ...theme.components?.PortalSelect,
            "&:first-child": {
                marginTop: theme.spacing(3),
                [theme.breakpoints.up("md")]: {
                    marginTop: theme.spacing(4),
                },
                [theme.breakpoints.up("xl")]: {
                    marginTop: theme.spacing(5),
                },
            },
        },
        input: {
            ...theme.components?.PortalInput,
            width: "100%",
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(4),
            "&:first-child": {
                marginTop: theme.spacing(3),
                [theme.breakpoints.up("md")]: {
                    marginTop: theme.spacing(4),
                },
                [theme.breakpoints.up("xl")]: {
                    marginTop: theme.spacing(5),
                },
            },
        },
        linkPrimary: {
            ...theme.components?.PortalLinkPrimary,
        },
        linkSecondary: {
            ...theme.components?.PortalLinkSecondary,
        },
        button: {
            ...theme.components?.PortalButton,
        },
    };
});

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const translate = useTranslate();
    const redirect = useRedirect();
    const login = useLogin();
    const { siteSettings: settings } = useAppContext();
    const classes = useStyles();
    const search = useLocation().search;
    const portal =
        new URLSearchParams(search).get("portal") ||
        Cookies.get("portal_portal") ||
        "";

    const onSubmit = (auth) => {
        const payload = { domain: settings?.domain, ...auth };
        setLoading(true);
        setError(null);
        login(
            payload,
            location.state ? location.state.nextPathname : "/"
        ).catch((error) => {
            setLoading(false);
            setError(error);
        });
    };

    const onChange = (event) => {
        if (!settings?.subdomains) return;
        const redirectDomain = settings?.subdomains?.find(
            (subdomain) => subdomain.slug === event.target.value
        );

        const redirectURL =
            APP_MODE === "development"
                ? `http://${redirectDomain.domain}:3000/login?portal=${redirectDomain.slug}`
                : `https://${redirectDomain.domain}/login?portal=${redirectDomain.slug}`;
        redirect(redirectURL);
    };

    const validateLogin = (values) => {
        const errors = {};
        if (!values.portal && settings?.subdomains?.length > 1) {
            errors.portal = translate("ra.validation.required");
        }
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        return errors;
    };

    const content = (
        <Form
            onSubmit={onSubmit}
            className={classes.form}
            validate={validateLogin}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {settings?.subdomains?.length > 1 && (
                        <Field
                            margin="normal"
                            required
                            fullWidth
                            id="portal"
                            label={translate("ra.auth.portal")}
                            name="portal"
                            disabled={loading}
                            onChange={onChange}
                            component={SelectField}
                            initialValue={portal}
                            options={settings?.subdomains?.map((option) => ({
                                id: option.id,
                                value: option.slug,
                                label: option.name,
                            }))}
                            className={classes.select}
                            inputProps={settings?.theme?.props?.PortalSelect}
                        />
                    )}
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={translate("ra.auth.email")}
                        name="email"
                        disabled={loading}
                        autoComplete="email"
                        component={InputField}
                        className={classes.input}
                        inputProps={settings?.theme?.props?.PortalInput}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        source="password"
                        label={translate("ra.auth.password")}
                        type="password"
                        id="password"
                        disabled={loading}
                        autoComplete="current-password"
                        component={InputField}
                        className={classes.input}
                        inputProps={settings?.theme?.props?.PortalInput}
                    />
                    {error && (
                        <Box mt={2}>
                            <Alert variant="outlined" severity="error">
                                {translate("ra.auth.sign_in_error")}
                            </Alert>
                        </Box>
                    )}
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="register"
                                className={classes.linkPrimary}
                            >
                                {translate("ra.auth.register.title")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="activatie"
                                className={classes.linkPrimary}
                            >
                                {translate("ra.auth.activate.title")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={loading}
                                className={classes.button}
                                startIcon={
                                    loading ? (
                                        <CircularProgress
                                            size={18}
                                            thickness={2}
                                        />
                                    ) : null
                                }
                            >
                                {loading
                                    ? translate("ra.auth.processing")
                                    : translate("ra.auth.sign_in")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="forgot-password"
                                className={classes.linkSecondary}
                            >
                                {translate("ra.auth.forgot_password")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.loginPageImage}
                    video={settings?.loginPageVideo}
                />
            }
            title={translate("ra.auth.sign_in")}
        />
    );
};

export default LoginPage;
