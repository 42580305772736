import React from "react";
import { Typography } from "@material-ui/core";

const ResourceTitle = ({ children }) => {
    return (
        <Typography
            component="h2"
            variant="h1"
            // style={{ marginBottom: "2.35rem" }}
        >
            {children}
        </Typography>
    );
};

export default ResourceTitle;
