import PortalThemeProvider from "../Theme/PortalThemeProvider";
import GradientPaper from "../Components/Surfaces/GradientPaper";
import Logo from "../Components/Logo";
import React from "react";
import Box from "@material-ui/core/Box";
import Copyright from "../Components/Copyright";
import { Grid } from "@material-ui/core";
import AuthTitle from "../Components/AuthTitle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    },
    panel: {
        padding: theme.spacing(8, 4, 8),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        minHeight: "100vh",
        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            padding: theme.spacing(24, 12, 12),
        },
    },
    content: {
        flex: 1,
    },
    title: {
        color: theme.components?.PortalAuthTitle?.color,
        fontSize: "34px",
    },
    aside: {
        position: "relative",
        backgroundColor: theme.palette.common.black,
    },
}));

const AuthLayout = ({ content, aside, title }) => {
    const classes = useStyles();

    return (
        // <PortalThemeProvider>
        <Grid container component="main" className={classes.root}>
            <Grid item md={12} lg={4} component={GradientPaper} elevation={6}>
                <Box className={classes.panel}>
                    <Box>
                        <Logo />
                    </Box>
                    <AuthTitle>{title}</AuthTitle>
                    <Box className={classes.content}>{content}</Box>
                    <Box mt={"auto"}>
                        <Copyright />
                    </Box>
                </Box>
            </Grid>
            <Grid item md={false} lg={8} className={classes.aside}>
                {aside}
            </Grid>
        </Grid>
        // </PortalThemeProvider>
    );
};

export default AuthLayout;
