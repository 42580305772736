import {
    AutocompleteInput,
    DeleteButton,
    Edit,
    ReferenceInput,
    ReferenceField,
    SaveButton,
    SimpleForm,
    TextInput,
    TextField,
    Toolbar,
    useEditController,
    useTranslate,
} from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import React from "react";
import ResourceEditToolbar from "../Components/ResourceEditToolbar";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const MaterialNameEditToolbar = (props) => {
    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton redirect={props.saveRedirect} />
        </Toolbar>
    );
};

const MaterialNameEdit = (props) => {
    const translate = useTranslate();
    const location = useLocation();
    const { record: materialName } = useEditController(props);

    const material_id =
        location.state && location.state.record
            ? location.state.record.material_id
            : undefined;

    const redirect = material_id
        ? `/materials/${material_id}/material-names`
        : false;

    return (
        <>
            {materialName && (
                <>
                    <ResourceTitle>{materialName.name}</ResourceTitle>
                </>
            )}
            <Edit {...props} title={<></>} actions={false}>
                <SimpleForm
                    toolbar={
                        <MaterialNameEditToolbar saveRedirect={redirect} />
                    }
                    redirect={redirect}
                >
                    <ReferenceField
                        label={translate("ra.fields.customer")}
                        link={false}
                        source="customer_id"
                        reference="customers"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        label={translate("ra.fields.material_code")}
                        link={false}
                        source="material_id"
                        reference="materials"
                    >
                        <TextField source="code" />
                    </ReferenceField>
                    <TextField
                        source="default_name"
                        label={translate("ra.fields.default_name")}
                    />
                    <TextInput
                        source="custom_name"
                        label={translate("ra.fields.custom_name")}
                    />
                </SimpleForm>
            </Edit>
        </>
    );
};

export default MaterialNameEdit;
