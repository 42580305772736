import * as React from "react";
import { Toolbar, SaveButton, DeleteButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const ResourceEditToolbar = (props) => {
    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton redirect={props.saveRedirect} />
            <DeleteButton
                mutationMode="pessimistic"
                undoable={false}
                redirect={props.deleteRedirect}
            />
        </Toolbar>
    );
};

export default ResourceEditToolbar;
