import React from "react";
import { useHistory } from "react-router-dom";
import {
    Datagrid,
    List,
    ReferenceField,
    TextField,
    DateField,
    useTranslate,
    Pagination,
    Filter,
    TextInput,
} from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import { Card, CardHeader, Grid } from "@material-ui/core";
import WeighingTicketChart from "./WeighingTicketChart";
import FormattedDateField from "../Components/Fields/FormattedDateField";
import LocalizedTextField from "../Components/Fields/LocalizedTextField";
import FormattedWeightField from "../Components/Fields/FormattedWeightField";
import EmptyList from "../Components/EmptyList";

const WeighingTicketPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const WeighingTicketList = (props) => {
    const history = useHistory();
    const translate = useTranslate();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.weighing_tickets")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <WeighingTicketChart />
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={translate("ra.resources.weighing_tickets")}
                    />
                    <List
                        {...props}
                        sort={{ field: "date", order: "DESC" }}
                        title={<></>}
                        perPage={25}
                        pagination={<WeighingTicketPagination />}
                        bulkActionButtons={false}
                        actions={null}
                        exporter={null}
                        filters={
                            <Filter>
                                <TextInput
                                    source="_q"
                                    label="Zoek op weegbon nr."
                                    alwaysOn
                                />
                            </Filter>
                        }
                        empty={
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.weighing_tickets"
                                )}
                            />
                        }
                    >
                        <Datagrid rowClick="show" size="medium">
                            <FormattedDateField
                                source="date"
                                label={translate("ra.fields.date")}
                            />
                            <TextField
                                source="number"
                                label={translate(
                                    "ra.fields.weighing_ticket_number"
                                )}
                            />
                            <FormattedWeightField
                                source="total_weight"
                                label={translate("ra.fields.total_weight")}
                            />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default WeighingTicketList;
