import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Icon } from "@material-ui/core";
import styles from "./CarouselSelect.module.css";

export const CarouselSelect = ({
    data = [],
    onChange = () => {},
    disableNext = false,
    disablePrev = false,
    initialValue = null,
}) => {
    const [index, setIndex] = useState(
        initialValue
            ? data.findIndex((item) => item.value == initialValue) || 0
            : data.length - 1
    );

    const changeHandler = (index) => {
        onChange(data[index].value);
    };

    return (
        <Box className={styles.root}>
            <button
                onClick={() => setIndex(index - 1)}
                className={styles.button}
                disabled={disablePrev || index === 0}
            >
                <Icon className={styles.icon}>arrow_left</Icon>
            </button>
            <Carousel
                index={index}
                autoPlay={false}
                animation="slide"
                navButtonsAlwaysInvisible={true}
                indicators={false}
                onChange={changeHandler}
                changeOnFirstRender={true}
                className={styles.carousel}
            >
                {data.map((item) => (
                    <div className={styles.carouselItem} key={item.value}>
                        {item.label}
                    </div>
                ))}
            </Carousel>
            <button
                onClick={() => setIndex(index + 1)}
                className={styles.button}
                disabled={disableNext || index === data.length - 1}
            >
                <Icon className={styles.icon}>arrow_right</Icon>
            </button>
        </Box>
    );
};

export default CarouselSelect;
