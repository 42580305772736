import { Field, Form } from "react-final-form";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";
import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import apiService from "../apiService";
import { makeStyles } from "../Theme/PortalThemeProvider";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AuthLayout from "../Layout/AuthLayout";
import { useAppContext } from "../appProvider";
import InputField from "../Components/Fields/InputField";
import { Link as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import Aside from "../Components/Aside";
import SelectField from "../Components/Fields/SelectField";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
    select: {
        ...theme.components?.PortalSelect,
        "&:first-child": {
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("md")]: {
                marginTop: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
                marginTop: theme.spacing(5),
            },
        },
    },
    input: {
        ...theme.components?.PortalInput,
        width: "100%",
        "&:first-child": {
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("md")]: {
                marginTop: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
                marginTop: theme.spacing(5),
            },
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
    linkPrimary: {
        ...theme.components?.PortalLinkPrimary,
    },
    linkSecondary: {
        ...theme.components?.PortalLinkSecondary,
    },
    button: {
        ...theme.components?.PortalButton,
    },
}));

const ForgotPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const classes = useStyles();
    const translate = useTranslate();
    const { siteSettings: settings } = useAppContext();
    const search = useLocation().search;
    const portal =
        new URLSearchParams(search).get("portal") ||
        Cookies.get("portal_portal") ||
        "";

    const handleSubmit = (data) => {
        const payload = { ...data, domain: settings?.domain };
        setLoading(true);
        setIsSubmitted(false);
        setError(null);
        apiService
            .sendResetPasswordLink(payload)
            .then((res) => {
                setLoading(false);
                setIsSubmitted(true);
            })
            .catch((error) => {
                setLoading(false);
                setError(
                    error.response?.data?.errors?.email[0] ??
                        error.response.data.message
                );
            });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.portal && settings?.subdomains?.length > 1) {
            errors.portal = translate("ra.validation.required");
        }
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        return errors;
    };

    const content = (
        <>
            {!isSubmitted && (
                <Form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            {settings?.subdomains?.length > 1 && (
                                <Field
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="portal"
                                    label={translate("ra.auth.portal")}
                                    name="portal"
                                    autoComplete="portal"
                                    disabled={loading}
                                    component={SelectField}
                                    initialValue={portal}
                                    options={settings?.subdomains?.map(
                                        (option) => ({
                                            id: option.id,
                                            value: option.slug,
                                            label: option.name,
                                        })
                                    )}
                                    className={classes.select}
                                    inputProps={
                                        settings?.theme?.props?.PortalSelect
                                    }
                                />
                            )}
                            <Field
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={translate("ra.auth.email")}
                                name="email"
                                disabled={loading}
                                autoComplete="email"
                                component={InputField}
                                className={classes.input}
                                inputProps={settings?.theme?.props?.PortalInput}
                            />
                            {error && (
                                <Box mt={2}>
                                    <Alert variant="outlined" severity="error">
                                        {error}
                                    </Alert>
                                </Box>
                            )}
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item xs>
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        className={classes.button}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress
                                                    size={18}
                                                    thickness={2}
                                                />
                                            ) : null
                                        }
                                    >
                                        {loading
                                            ? translate("ra.auth.processing")
                                            : translate(
                                                  "ra.auth.send_password_reset_link"
                                              )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            )}
            {isSubmitted && (
                <Box mt={4} mb={4}>
                    <Typography variant="body1" color="textSecondary">
                        {translate("ra.auth.forgot_password_link_sent")}
                    </Typography>
                </Box>
            )}
            <Link
                component={RouterLink}
                to="login"
                className={clsx([classes.linkSecondary], {
                    ["disabled"]: loading,
                })}
            >
                {translate("ra.auth.back_to_login_page")}
            </Link>
        </>
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.forgotPasswordImage}
                    video={settings?.forgotPasswordVideo}
                />
            }
            title={translate("ra.auth.forgot_password")}
        />
    );
};

export default ForgotPasswordPage;
