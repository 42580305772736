import {
    Datagrid,
    List,
    TextField,
    useTranslate,
    TextInput,
    Pagination,
    Filter,
} from "react-admin";
import { useHistory } from "react-router-dom";
import ResourceTitle from "../Components/ResourceTitle";
import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import FormattedDateField from "../Components/Fields/FormattedDateField";
import CurrencyField from "../Components/Fields/CurrencyField";
import EmptyList from "../Components/EmptyList";

const InvoicePagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const InvoiceList = ({ permissions, ...props }) => {
    const history = useHistory();
    const translate = useTranslate();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.invoices")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={translate("ra.dashboard.overview")} />
                    <List
                        {...props}
                        sort={{ field: "date", order: "DESC" }}
                        title={<></>}
                        perPage={25}
                        pagination={<InvoicePagination />}
                        bulkActionButtons={false}
                        actions={null}
                        exporter={null}
                        filters={
                            <Filter>
                                <TextInput
                                    source="_q"
                                    label="Zoek op factuurnummer"
                                    alwaysOn
                                />
                            </Filter>
                        }
                        empty={
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.invoices"
                                )}
                            />
                        }
                    >
                        <Datagrid rowClick="show" size="medium">
                            <FormattedDateField
                                source="date"
                                label={translate("ra.fields.date")}
                            />
                            <TextField
                                source="number"
                                label={translate("ra.fields.invoice_number")}
                            />
                            <CurrencyField
                                source="total_price"
                                label={translate("ra.fields.total_price")}
                            />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default InvoiceList;
