import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PortalThemeProvider from "../../Theme/PortalThemeProvider";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        color: theme.palette.secondary.contrastText,
        background: theme.palette.background.paper,
    },
}));

const GradientPaper = (props) => {
    const classes = useStyles(props);

    return <Paper {...props} className={clsx(classes.root, props.className)} />;
};

export default GradientPaper;
