import "./App.css";

import authProvider from "./authProvider";
import i18nProvider from "./i18nProvider";
import dataProvider from "./dataProvider";
import { Admin, Resource, RouteWithoutLayout, useTranslate } from "react-admin";
import Dashboard from "./Dashboard/Dashboard";
import WeighingTicketList from "./WeighingTickets/WeighingTicketList";
import WeighingTicketShow from "./WeighingTickets/WeighingTicketShow";
import InvoiceList from "./Invoices/InvoiceList";
import MaterialList from "./Materials/MaterialList";
import InvoiceShow from "./Invoices/InvoiceShow";
import LoginPage from "./Auth/LoginPage";
import ForgotPasswordPage from "./Auth/ForgotPasswordPage";
import ResetPasswordPage from "./Auth/ResetPasswordPage";
import RegisterPage from "./Auth/RegisterPage";
import Layout from "./Layout/Layout";
import AdminUserList from "./Admin/Users/UserList";
import AdminUserCreate from "./Admin/Users/UserCreate";
import AdminUserEdit from "./Admin/Users/UserEdit";
import ManagerUserList from "./Manager/Users/UserList";
import ManagerUserCreate from "./Manager/Users/UserCreate";
import ManagerUserEdit from "./Manager/Users/UserEdit";
import MaterialEdit from "./Materials/MaterialEdit";
import MaterialTypeList from "./MaterialTypes/MaterialTypeList";
import MaterialTypeEdit from "./MaterialTypes/MaterialTypeEdit";
import MaterialTypeCreate from "./MaterialTypes/MaterialTypeCreate";
import MaterialNameEdit from "./MaterialNames/MaterialNameEdit";

import { Icon } from "@material-ui/core";
import { Route } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import { ProfileEdit } from "./Profile/ProfileEdit";
import CustomerList from "./Customers/CustomerList";
import CustomerShow from "./Customers/CustomerShow";
import ActivatePage from "./Auth/ActivatePage";
import AppProvider from "./appProvider";
import { ModalProvider } from "./Components/OverlayModal";
import PortalThemeProvider from "./Theme/PortalThemeProvider";

const App = () => {
    const translate = useTranslate();
    const history = createHistory();

    return (
        <ModalProvider>
            <AppProvider>
                <PortalThemeProvider>
                    <Admin
                        layout={Layout}
                        loginPage={LoginPage}
                        dashboard={Dashboard}
                        dataProvider={dataProvider}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        history={history}
                        customRoutes={[
                            <RouteWithoutLayout
                                key="forgot-password"
                                path="/forgot-password"
                                component={ForgotPasswordPage}
                                noLayout={true}
                                exact
                            />,
                            <RouteWithoutLayout
                                key="reset-password"
                                path="/reset-password"
                                component={ResetPasswordPage}
                                noLayout={true}
                                exact
                            />,
                            <RouteWithoutLayout
                                key="register"
                                path="/register"
                                component={RegisterPage}
                                noLayout={true}
                                exact
                            />,
                            <RouteWithoutLayout
                                key="activatie"
                                path="/activatie"
                                component={ActivatePage}
                                noLayout={true}
                                exact
                            />,
                            <Route
                                key="my-profile"
                                path="/my-profile"
                                render={() => <ProfileEdit />}
                            />,
                        ]}
                    >
                        {(permissions) => {
                            const admin = [
                                <Resource
                                    key="users"
                                    name="users"
                                    options={{
                                        label: translate("ra.resources.users"),
                                    }}
                                    list={AdminUserList}
                                    edit={AdminUserEdit}
                                    create={AdminUserCreate}
                                    icon={() => (
                                        <Icon className="fal fa-user-friends" />
                                    )}
                                />,
                                <Resource
                                    key="customers"
                                    name="customers"
                                    options={{
                                        label: translate(
                                            "ra.resources.customers"
                                        ),
                                    }}
                                    list={CustomerList}
                                    show={CustomerShow}
                                    icon={() => (
                                        <Icon className="fal fa-briefcase" />
                                    )}
                                />,
                                <Resource key="roles" name="roles" />,
                                <Resource
                                    key="materials"
                                    name="materials"
                                    options={{
                                        label: translate(
                                            "ra.resources.materials"
                                        ),
                                    }}
                                    list={MaterialList}
                                    edit={MaterialEdit}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                                <Resource
                                    key="material-names"
                                    name="material-names"
                                    edit={MaterialNameEdit}
                                    options={{
                                        label: translate(
                                            "ra.resources.material_names"
                                        ),
                                    }}
                                />,
                                <Resource
                                    key="material-types"
                                    name="material-types"
                                    options={{
                                        label: translate(
                                            "ra.resources.material_types"
                                        ),
                                    }}
                                    list={MaterialTypeList}
                                    edit={MaterialTypeEdit}
                                    create={MaterialTypeCreate}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                            ];

                            const manager = [
                                <Resource
                                    key="users"
                                    name="users"
                                    options={{
                                        label: translate(
                                            "ra.resources.employees"
                                        ),
                                    }}
                                    list={ManagerUserList}
                                    edit={ManagerUserEdit}
                                    create={ManagerUserCreate}
                                    icon={() => (
                                        <Icon className="fal fa-user-friends" />
                                    )}
                                />,
                                <Resource key="customers" name="customers" />,
                                <Resource
                                    key="materials"
                                    name="materials"
                                    options={{
                                        label: translate(
                                            "ra.resources.materials"
                                        ),
                                    }}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                                <Resource
                                    key="weighing-tickets"
                                    name="weighing-tickets"
                                    options={{
                                        label: translate(
                                            "ra.resources.weighing_tickets"
                                        ),
                                    }}
                                    list={WeighingTicketList}
                                    show={WeighingTicketShow}
                                    icon={() => (
                                        <Icon className="fal fa-clipboard-check" />
                                    )}
                                />,
                                <Resource
                                    key="invoices"
                                    name="invoices"
                                    options={{
                                        label: translate(
                                            "ra.resources.invoices"
                                        ),
                                    }}
                                    list={InvoiceList}
                                    show={InvoiceShow}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                            ];

                            const employee = [
                                <Resource key="users" name="users" />,
                                <Resource key="customers" name="customers" />,
                                <Resource
                                    key="materials"
                                    name="materials"
                                    options={{
                                        label: translate(
                                            "ra.resources.materials"
                                        ),
                                    }}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                                <Resource
                                    key="weighing-tickets"
                                    name="weighing-tickets"
                                    options={{
                                        label: translate(
                                            "ra.resources.weighing_tickets"
                                        ),
                                    }}
                                    list={WeighingTicketList}
                                    show={WeighingTicketShow}
                                    icon={() => (
                                        <Icon className="fal fa-clipboard-check" />
                                    )}
                                />,
                                <Resource
                                    key="invoices"
                                    name="invoices"
                                    options={{
                                        label: translate(
                                            "ra.resources.invoices"
                                        ),
                                    }}
                                    list={InvoiceList}
                                    show={InvoiceShow}
                                    icon={() => (
                                        <Icon className="fal fa-list" />
                                    )}
                                />,
                            ];

                            switch (permissions) {
                                case "guest":
                                    return [
                                        <Resource key="guest" name="guest" />,
                                    ];
                                case "employee":
                                    return employee;
                                case "manager":
                                    return manager;
                                case "admin":
                                    return admin;
                            }
                        }}
                    </Admin>
                </PortalThemeProvider>
            </AppProvider>
        </ModalProvider>
    );
};

export default App;
