import React, { createContext, useContext, useEffect, useState } from "react";
import styles from "./OverlayModal.module.css";

export const LoadingSpinner = () => (
    <>
        <span className={styles.icon}>
            <img src="/icons/gear_animated.svg" alt="" />
        </span>
        <div>Laden, even geduld aub...</div>
    </>
);

export const ErrorMessage = ({
    message = "Oeps, er is een fout opgetreden.",
}) => <div>{message}</div>;

const ModalContext = createContext({
    content: null,
    setContent: () => {},
});

export const useModal = () => {
    return useContext(ModalContext);
};

const OverlayModal = () => {
    const { content, setContent } = useContext(ModalContext);

    return (
        content && (
            <div className={styles.container}>
                <div className={styles.content}>{content}</div>
            </div>
        )
    );
};

export const ModalProvider = ({ children }) => {
    const [content, setContent] = useState(null);
    const value = { content, setContent };

    return (
        <ModalContext.Provider value={value}>
            {children}
            <OverlayModal />
        </ModalContext.Provider>
    );
};

export default OverlayModal;
