import {
    Edit,
    SimpleForm,
    AutocompleteInput,
    PasswordInput,
    ReferenceInput,
    TextInput,
    BooleanInput,
    useTranslate,
    useEditController,
    useMutation,
    useRedirect,
    useNotify,
    useRefresh,
} from "react-admin";
import ResourceTitle from "../../Components/ResourceTitle";
import React, { useCallback } from "react";
import ResourceEditToolbar from "../../Components/ResourceEditToolbar";
import { Grid } from "@material-ui/core";

const UserEdit = (props) => {
    const translate = useTranslate();
    const { record: user } = useEditController(props);
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();

    const save = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: "update",
                        resource: "users",
                        payload: { data: values, id: values.id },
                    },
                    { returnPromise: true }
                );
                notify("ra.notification.updated", {
                    messageArgs: { smart_count: 1 },
                });
                redirect("list", props.basePath);
                refresh();
            } catch (error) {
                notify("ra.message.invalid_form", { type: "warning" });
                refresh();
            }
        },
        [mutate]
    );

    return (
        <Grid container spacing={4}>
            {user && (
                <Grid item xs={12}>
                    <ResourceTitle>
                        {user.first_name} {user.last_name}
                    </ResourceTitle>
                </Grid>
            )}
            <Grid item xs={12}>
                <Edit {...props} title={<></>}>
                    <SimpleForm toolbar={<ResourceEditToolbar />} save={save}>
                        <BooleanInput
                            source="is_active"
                            variant="standard"
                            label={translate("ra.fields.active")}
                        />
                        <TextInput
                            source="first_name"
                            variant="standard"
                            label={translate("ra.fields.first_name")}
                        />
                        <TextInput
                            source="last_name"
                            variant="standard"
                            label={translate("ra.fields.last_name")}
                        />
                        <TextInput
                            label={translate("ra.fields.email")}
                            source="email"
                            variant="standard"
                            type="email"
                        />
                        <TextInput
                            label={translate("ra.fields.phone_number")}
                            source="phone_number"
                            variant="standard"
                        />
                        <PasswordInput
                            source="password"
                            variant="standard"
                            label={translate("ra.auth.new_password")}
                        />
                        <PasswordInput
                            source="password_confirmation"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            label={translate("ra.auth.new_password_confirm")}
                        />
                        <ReferenceInput
                            label={translate("ra.fields.customer")}
                            source="customer_id"
                            variant="standard"
                            reference="customers"
                            sort={{ field: "name", order: "ASC" }}
                            filterToQuery={(searchText) => ({
                                name: searchText,
                            })}
                        >
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput
                            label={translate("ra.fields.role")}
                            source="role_id"
                            variant="standard"
                            reference="roles"
                            sort={{ field: "name", order: "ASC" }}
                            filterToQuery={(searchText) => ({
                                name: searchText,
                            })}
                        >
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>
    );
};

export default UserEdit;
