import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    useEditController,
    useTranslate,
} from "react-admin";
import { Grid } from "@material-ui/core";
import ResourceTitle from "../Components/ResourceTitle";

const CustomerShow = (props) => {
    const translate = useTranslate();
    const { record: customer } = useEditController(props);

    return (
        <Grid container spacing={4}>
            {customer && (
                <Grid item xs={12}>
                    <ResourceTitle>{customer.name}</ResourceTitle>
                </Grid>
            )}
            <Grid item xs={12}>
                <Show {...props} title={<></>}>
                    <SimpleShowLayout>
                        <TextField
                            source="name"
                            variant="standard"
                            label={translate("ra.fields.name")}
                        />
                        <TextField
                            source="credit_number"
                            variant="standard"
                            label={translate("ra.fields.creditor_number")}
                        />
                        <TextField
                            source="activation_code"
                            variant="standard"
                            label={translate("ra.fields.activation_code")}
                        />
                    </SimpleShowLayout>
                </Show>
            </Grid>
        </Grid>
    );
};

export default CustomerShow;
