const formatter = (options) => new Intl.NumberFormat("nl-NL", options);

export const Price = ({
    children,
    options = {
        style: "currency",
        currency: "EUR",

        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    },
}) => {
    if (isNaN(children)) return <>{children}</>;

    return formatter(options).format(children / 100);
};

export default Price;
