import { createContext, useContext, useState } from "react";

const DataFilterContext = createContext(null);

export const useDataFilterContext = () => {
    return useContext(DataFilterContext);
};

export const DataFilterProvider = ({ children }) => {
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedOverviewYears, setSelectedOverviewYears] = useState({
        first: "",
        second: "",
    });
    const [selectedYears, setSelectedYears] = useState({
        first: "",
        second: "",
    });

    return (
        <DataFilterContext.Provider
            value={{
                selectedMaterials,
                setSelectedMaterials,
                selectedYears,
                setSelectedYears,
                selectedOverviewYears,
                setSelectedOverviewYears,
            }}
        >
            {children}
        </DataFilterContext.Provider>
    );
};

export default DataFilterProvider;
