export default {
    ra: {
        auth: {
            processing: "Verwerken...",
            portal: "Vestiging",
            email: "E-mailadres",
            forgot_password: "Wachtwoord Vergeten?",
            reset_password: "Wachtwoord herstellen",
            new_password: "Nieuw wachtwoord",
            new_password_confirm: "Nieuw wachtwoord bevestigen",
            send_password_reset_link: "Verstuur link voor wachtwoordherstel",
            forgot_password_link_sent:
                "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            back_to_login_page: "Terug naar loginpagina",
            reset_password_message:
                "Het wachtwoord van uw account is gewijzigd.",
            register: {
                title: "Registreren",
                content:
                    "Hebt u nog geen account voor het portal. Neem contact met ons op dan maken wij een account aan.",
                button: "Stuur een verzoek",
                back: "Terug",
            },
            activate: {
                title: "Activatie",
                content:
                    "Heeft u een activatiecode ontvangen, gebruik het onderstaande formulier en activeer uw account.",
                button: "Activeren",
                back: "Terug",
            },
            activation_error: "Activatie mislukt, probeer opnieuw a.u.b",
            activation_success:
                "De activatie is succesvol afgerond, keer terug naar de loginpagina om in te loggen.",
        },
        dashboard: {
            dashboard: "Dashboard",
            overview: "Overzicht",
            current_situation: "Actuele stand van zaken",
            new_users: "Nieuwe Gebruikers",
            materials: "Materialen",
            type_or_select_category: "Type of selecteer een categorie",
            no_materials_selected:
                "Geen materialen geselecteerd. Selecteer minimaal één materiaal uit de lijst.",
            period: "Periode",
        },
        statistics: {
            total: "Totale",
            total_revenue: "Totale opbrengst",
            total_weight: "Totaal gewicht",
            total_weight_kg: "Totale gewicht (KG)",
            tonnage: "Tonnage",
            revenue: "Opbrengst",
            average_revenue: "Gemiddelde opbrengst",
            total_users: "Totaal aantal gebruikers",
            avg_bill: "Gem. waarde",
            price: "Prijs",
        },
        fields: {
            date: "Datum",
            date_registered: "Datum aangem.",
            invoice_number: "Factuurnummer",
            customer: "Klant",
            email: "E-mailadres",
            type: "Type",
            status: "Status",
            order_number: "Order nr.",
            weighing_ticket_number: "Weegbon nr.",
            weight: "Gewicht",
            total_weight: "Totaal gewicht",
            total_price: "Bedrag",
            code: "Code",
            material_type: "Materiaalsoort",
            material_name: "Materiaalnaam",
            material_code: "Materiaalcode",
            name: "Naam",
            default_name: "Standaardnaam",
            custom_name: "Aangepaste naam",
            password: "Wachtwoord",
            confirm_password: "Wachtwoord bevestigen",
            active: "Actief",
            inactive: "Inactief",
            description: "Omschrijving",
            creditor_number: "Crediteurnummer",
            activation_code: "Activatiecode",
            first_name: "Voornaam",
            last_name: "Achternaam",
            material_description: "Materiaalomschrijving",
            unit_price: "Prijs per eenheid",
            phone_number: "Telefoonnummer",
            colour: "Kleur",
            co2_coefficient: "CO2 koëfficiënt",
            role: "Rol",
        },
        resources: {
            weighing_tickets: "Weegbonnen",
            weighing_ticket: "Weegbon",
            invoices: "Facturen",
            invoice: "Factuur",
            users: "Gebruikers",
            customers: "Klanten",
            employees: "Werknemers",
            materials: "Materialen",
            material_types: "Materiaalsoorten",
            material_names: "Materiaalnamen",
        },
        no_resource_found: {
            text: "Geen %{resource} gevonden",
            weighing_tickets: "weegbonnen",
            invoices: "facturen",
            users: "gebruikers",
            customers: "klanten",
            materials: "materialen",
            material_types: "materiaalsoorten",
            material_names: "materiaalnamen",
        },
        actions: {
            all_weighing_tickets: "Alle weegbonnen",
            all_invoices: "Alle facturen",
            all_users: "Alle gebruikers",
            compare_to_other_period: "Vergelijk met andere periode",
        },
        invoice_types: {
            sales: "Verkopen",
            purchase: "Aankopen",
        },
        weighing_ticket_types: {
            weighting: "Weging",
            sort_report: "Sorteer rapport",
        },
        pages: {
            add_user: "Gebruiker Toevoegen",
            add_employee: "Werknemer Toevoegen",
            add_material_type: "Materiaalsoort Toevoegen",
            add_material_name: "Materiaalnaam Toevoegen",
        },
        user_menu: {
            profile: "Profiel instellingen",
            logout: "Uitloggen",
        },
        profile: {
            update_profile: "Profiel bewerken",
            email: "E-mailadres",
            first_name: "Voornaam",
            last_name: "Achternaam",
            current_password: "Huidig wachtwoord",
            new_password: "Nieuw wachtwoord",
            new_password_confirm: "Nieuw wachtwoord bevestigen",
            messages: {
                profile_update_success: "Profiel bijgewerkt",
                profile_update_fail:
                    "Het is niet gelukt om het profiel te bijwerken. Probeer het opnieuw aub.",
                profile_update_error:
                    "Er is een technische fout opgetreden. Probeer het later aub.",
                password_update_success:
                    "Het wachtwoord van uw account is gewijzigd.",
                password_update_fail:
                    "Het is niet gelukt om het wachtwoord voor je account te wijzigen. Probeer het opnieuw aub.",
                password_update_error:
                    "Er is een technische fout opgetreden. Probeer het later aub.",
            },
        },
    },
};
