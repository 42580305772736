import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import styles from "./VideoCover.module.css";

const VideoCover = ({ src, loop, aspectRatio }) => {
    const element = useRef(null);
    const [style, setStyle] = useState(null);

    const resize = () => {
        if (!element?.current) return;

        const dimensions = element.current.getBoundingClientRect();
        setStyle({
            width: `${dimensions.width}px`,
            height: `${dimensions.width * (1 / aspectRatio)}px`,
            minHeight: `${dimensions.height}px`,
            minWidth: `${dimensions.height * aspectRatio}px`,
        });
    };

    useEffect(() => resize(), [element]);

    useEffect(() => {
        window.addEventListener("resize", resize);
        return () => removeEventListener("resize", resize());
    }, []);

    return (
        <div className={styles.videoCover} ref={element}>
            {src && (
                <iframe
                    style={style}
                    src={src}
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
        </div>
    );
};

export default VideoCover;

VideoCover.propTypes = {
    src: PropTypes.string,
    aspectRatio: PropTypes.number,
    loop: PropTypes.bool,
};

VideoCover.defaultProps = {
    aspectRatio: 21 / 9,
    loop: true,
};
