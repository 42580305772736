import {
    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
    AutocompleteInput,
    ReferenceInput,
    useTranslate,
    useMutation,
    useRedirect,
    useNotify,
} from "react-admin";
import ResourceTitle from "../../Components/ResourceTitle";
import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";

const UserCreate = (props) => {
    const translate = useTranslate();
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const notify = useNotify();

    const save = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: "create",
                        resource: "users",
                        payload: { data: values },
                    },
                    { returnPromise: true }
                );
                notify("ra.notification.created", {
                    messageArgs: { smart_count: 1 },
                });
                redirect("list", props.basePath);
            } catch (error) {
                notify("ra.message.invalid_form", { type: "warning" });
            }
        },
        [mutate]
    );

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.pages.add_employee")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Create {...props} title={<></>}>
                    <SimpleForm save={save}>
                        <TextInput
                            source="first_name"
                            variant="standard"
                            label={translate("ra.fields.first_name")}
                        />
                        <TextInput
                            source="last_name"
                            variant="standard"
                            label={translate("ra.fields.last_name")}
                        />
                        <TextInput
                            label={translate("ra.fields.email")}
                            source="email"
                            type="email"
                            variant="standard"
                        />
                        <TextInput
                            label={translate("ra.fields.phone_number")}
                            source="phone_number"
                            variant="standard"
                        />
                        <PasswordInput
                            source="password"
                            variant="standard"
                            label={translate("ra.fields.password")}
                        />
                        <PasswordInput
                            source="password_confirmation"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            label={translate("ra.fields.confirm_password")}
                        />
                    </SimpleForm>
                </Create>
            </Grid>
        </Grid>
    );
};

export default UserCreate;
