import React, { useEffect, useState } from "react";
import apiService from "../apiService";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ResourceTitle from "../Components/ResourceTitle";
import CardHeaderButton from "../Components/CardHeaderButton";
import { useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";
import { makeStyles } from "../Theme/PortalThemeProvider";

const useStyles = makeStyles(() => ({
    tableRow: {
        "&:hover": {
            background: "#F8F8F8",
            cursor: "pointer",
        },
    },
}));

const DashboardAdmin = () => {
    const [overviewData, setOverviewData] = useState({});

    const classes = useStyles();
    const history = useHistory();
    const translate = useTranslate();

    const getOverviewData = (params) => {
        apiService
            .getAdminDashboardData(params)
            .then((res) => setOverviewData(res.data));
    };

    useEffect(() => {
        getOverviewData();
    }, []);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.dashboard.dashboard")}
                </ResourceTitle>
            </Grid>
            <Grid item container spacing={4}>
                <Grid item xs={7}>
                    <Card>
                        <CardHeader
                            title={translate("ra.dashboard.new_users")}
                            action={CardHeaderButton({
                                url: "/users",
                                label: translate("ra.actions.all_users"),
                            })}
                        />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                        {translate("ra.fields.date_registered")}
                                    </TableCell>
                                    <TableCell>
                                        {translate("ra.fields.customer")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {overviewData.users_data && (
                                <TableBody>
                                    {overviewData.users_data.map((user) => (
                                        <TableRow
                                            className={classes.tableRow}
                                            key={user.id}
                                            onClick={() =>
                                                history.push(
                                                    `/users/${user.id}`
                                                )
                                            }
                                        >
                                            <TableCell>
                                                {user.is_active &&
                                                    translate(
                                                        "ra.fields.active"
                                                    )}
                                                {!user.is_active &&
                                                    translate(
                                                        "ra.fields.active"
                                                    )}
                                            </TableCell>
                                            <TableCell>
                                                {user.created_at}
                                            </TableCell>
                                            <TableCell>
                                                {user.customer_name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </Card>
                </Grid>
                <Grid item xs={5}>
                    <Card>
                        <CardContent>
                            {overviewData.statistics_data?.total_users && (
                                <div>
                                    {translate("ra.statistics.total_users")}:{" "}
                                    {overviewData.statistics_data?.total_users}
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardAdmin;
