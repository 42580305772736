import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            ...theme.components?.PortalAuthTitle,
        },
    };
});

const AuthTitle = ({ children }) => {
    const classes = useStyles();

    return (
        <Typography component="h1" variant="h4" className={classes.title}>
            {children}
        </Typography>
    );
};

export default AuthTitle;
