export const defaultFont = {
    fontFamily: ["Roboto", "sans-serif"].join(", "),
};
export const headingFont = {
    fontFamily: ["Univia-Pro", "sans-serif"].join(", "),
    fontWeight: 900,
    fontStyle: "italic",
    textTransform: "uppercase",
    color: "#2B3B46",
    fontSize: "1.5rem",
};

export default {
    ...defaultFont,
    fontSize: 18,
    lineHeight: 1.5,
    h1: {
        ...headingFont,
        fontSize: "2.125rem",
    },
    h2: {
        ...headingFont,
        fontSize: "1.75rem",
    },
    h3: headingFont,
    h4: headingFont,
    h5: headingFont,
    h6: headingFont,
    subtitle1: headingFont,
    subtitle2: headingFont,
    button: {
        textTransform: "normal",
    },
    overline: {
        textTransform: "normal",
    },
};
