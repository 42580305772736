import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    Icon,
    Popover,
    FormControlLabel,
    FormGroup,
    createStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import SearchBar from "./SearchBar";

const MaterialLabel = ({ name, colour }) => {
    return (
        <div style={{ color: colour, display: "flex", alignItems: "center" }}>
            <span
                style={{
                    display: "inline-block",
                    width: ".75rem",
                    height: ".75rem",
                    marginRight: ".5rem",
                    borderRadius: "50%",
                    background: colour,
                }}
            />
            {name}
        </div>
    );
};

const MaterialSelector = ({
    options = [],
    value = [],
    onChange = () => {},
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [filterQuery, setFilterQuery] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);

    const buttonRef = useRef(null);

    const toggleCategory = (id) => {
        if (value.find((item) => item.id == id))
            onChange([...value.filter((item) => item.id != id)]);
        else onChange([...value, options.find((item) => item.id == id)]);
    };

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        if (!filterQuery) setFilteredOptions(options);
        else
            setFilteredOptions(
                options.filter((item) => {
                    return item.name
                        .toLowerCase()
                        .includes(filterQuery.toLowerCase());
                })
            );
    }, [filterQuery]);

    return (
        <>
            <Button
                variant="contained"
                color="default"
                onClick={() => setShowPopup(!showPopup)}
                ref={buttonRef}
            >
                {"Materialen"}
                <Icon style={{ width: ".75em" }}>arrow_drop_down</Icon>
            </Button>

            <Backdrop
                open={showPopup}
                onClose={() => setShowPopup(false)}
                style={{ zIndex: 9999 }}
            >
                <Popover
                    open={showPopup}
                    anchorEl={buttonRef.current}
                    onClose={() => setShowPopup(false)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Box
                        style={{
                            padding: "1.3rem",
                            borderBottom: "1px solid grey",
                        }}
                    >
                        <SearchBar
                            value={filterQuery}
                            onChange={(value) => setFilterQuery(value)}
                            placeholder={"Zoeken op type materiaal..."}
                        />
                    </Box>
                    <Box
                        style={{
                            padding: "1.3rem",
                            maxHeight: "14rem",
                            overflow: "auto",
                        }}
                    >
                        <FormGroup>
                            {filteredOptions.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    control={
                                        <Checkbox
                                            checked={
                                                !!value.find(
                                                    (item) =>
                                                        item.id == option.id
                                                )
                                            }
                                            value={option.id}
                                            onChange={(event) =>
                                                toggleCategory(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <MaterialLabel
                                            name={option.name}
                                            colour={option.colour}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </Popover>
            </Backdrop>
        </>
    );
};

export default MaterialSelector;
