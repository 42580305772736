import { TextField } from "@material-ui/core";
import React from "react";

export const InputField = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input, // eslint-disable-line react/prop-types
    inputProps = {},
    ...props
}) => {
    return (
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            inputProps={inputProps}
            {...input}
            {...props}
            fullWidth
        />
    );
};

export default InputField;
