import {
    CreateButton,
    Datagrid,
    List,
    NumberField,
    Pagination,
    TextField,
    useTranslate,
} from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import { ColorField } from "react-admin-color-input";
import EmptyList from "../Components/EmptyList";
import FloatField from "../Components/Fields/FloatField";

const MaterialTypePagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const MaterialTypeList = (props) => {
    const translate = useTranslate();
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.material_types")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={translate("ra.dashboard.overview")}
                        action={<CreateButton />}
                    />
                    <List
                        {...props}
                        perPage={25}
                        pagination={<MaterialTypePagination />}
                        title={<></>}
                        bulkActionButtons={false}
                        actions={false}
                        exporter={false}
                        sort={{ field: "name", order: "ASC" }}
                        empty={
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.material_types"
                                )}
                            />
                        }
                    >
                        <Datagrid rowClick="edit">
                            <TextField
                                source="name"
                                label={translate("ra.fields.name")}
                            />
                            <NumberField
                                options={{
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                }}
                                source="co2_avoidance_coefficient"
                                label={translate("ra.fields.co2_coefficient")}
                            />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MaterialTypeList;
