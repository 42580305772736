import React, {
    useState,
    useCallback,
    useMemo,
    createContext,
    useContext,
} from "react";
import {
    PasswordInput,
    SimpleForm,
    required,
    useNotify,
    TextInput,
    SaveContextProvider,
    useGetIdentity,
    useTranslate,
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import apiService from "../apiService";
import { makeStyles } from "@material-ui/core/styles";
import log from "d3-scale/src/log";

// const useStyles = makeStyles({
//     errorMessage: {
//         color: "#C8162F",
//     },
// });

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [profileVersion, setProfileVersion] = useState(0);
    const context = useMemo(
        () => ({
            profileVersion,
            refreshProfile: () => {
                // Increment the version which will update the state
                setProfileVersion((currentVersion) => currentVersion + 1);
            },
        }),
        [profileVersion]
    );

    return (
        <ProfileContext.Provider value={context}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = () => {
    const notify = useNotify();
    // const styles = useStyles();
    const translate = useTranslate();
    const [saving, setSaving] = useState(false);
    // const [errors, setErrors] = useState({});
    const { loaded, identity } = useGetIdentity();
    const { refreshProfile } = useProfile();

    const handleSave = useCallback(
        (values) => {
            setSaving(true);
            apiService
                .updateProfile({ ...values })
                .then(
                    () => {
                        setSaving(false);
                        notify(
                            translate(
                                "ra.profile.messages.profile_update_success"
                            )
                        );
                        refreshProfile();
                    },
                    (errors) => {
                        setSaving(false);
                        // setErrors({ ...errors.response.data.errors });
                        notify(
                            translate(
                                "ra.profile.messages.profile_update_fail"
                            ),
                            "error"
                        );
                    }
                )
                .catch(() => {
                    setSaving(false);
                    notify(
                        translate("ra.profile.messages.profile_update_error"),
                        "error"
                    );
                });
        },
        [apiService, notify, refreshProfile]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            saving,
        }),
        [saving, handleSave]
    );

    if (!loaded) {
        return null;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={translate("ra.profile.update_profile")}
                    />
                    <SaveContextProvider value={saveContext}>
                        <SimpleForm
                            save={handleSave}
                            record={identity ? identity : {}}
                        >
                            <TextInput
                                label={translate("ra.profile.email")}
                                variant="standard"
                                source="email"
                                disabled={true}
                            />
                            <TextInput
                                label={translate("ra.profile.first_name")}
                                variant="standard"
                                source="first_name"
                            />
                            <TextInput
                                label={translate("ra.profile.last_name")}
                                variant="standard"
                                source="last_name"
                            />
                            <PasswordInput
                                label={translate("ra.profile.current_password")}
                                variant="standard"
                                source="current_password"
                            />
                            {/*{errors.current_password?.[0] && (*/}
                            {/*    <small className={styles.errorMessage}>*/}
                            {/*        {errors["current_password"][0]}*/}
                            {/*    </small>*/}
                            {/*)}*/}
                            <PasswordInput
                                label={translate("ra.profile.new_password")}
                                variant="standard"
                                source="password"
                            />
                            {/*{errors.password?.[0] && (*/}
                            {/*    <small className={styles.errorMessage}>*/}
                            {/*        {errors["password"][0]}*/}
                            {/*    </small>*/}
                            {/*)}*/}
                            <PasswordInput
                                InputLabelProps={{ shrink: true }}
                                label={translate(
                                    "ra.profile.new_password_confirm"
                                )}
                                variant="standard"
                                source="password_confirmation"
                            />
                            {/*{errors.password_confirmation?.[0] && (*/}
                            {/*    <small className={styles.errorMessage}>*/}
                            {/*        {errors["password_confirmation"][0]}*/}
                            {/*    </small>*/}
                            {/*)}*/}
                        </SimpleForm>
                    </SaveContextProvider>
                </Card>
            </Grid>
        </Grid>
    );
};
