import {
    Edit,
    SimpleForm,
    useTranslate,
    useEditController,
    NumberInput,
    Datagrid,
    TextField,
    CreateButton,
    TopToolbar,
    ReferenceManyField,
    ReferenceField,
    Pagination,
    Button,
    Link,
    ReferenceInput,
    AutocompleteInput,
} from "react-admin";
import React from "react";
import ResourceTitle from "../Components/ResourceTitle";
import { ColorInput } from "react-admin-color-input";
import { useHistory } from "react-router-dom";
import EmptyList from "../Components/EmptyList";
import { makeStyles } from "../Theme/PortalThemeProvider";
import Add from "@material-ui/icons/Add";
import round from "lodash.round";
import { Grid } from "@material-ui/core";

const MaterialNamePagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const AddNewMaterialNameButton = ({ material }) => {
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            variant="text"
            component={Link}
            to={{
                pathname: "/material-names/create",
                // Here we specify the initial record for the create view
                state: { record: { material_id: material.id } },
            }}
            label="Add a material name"
        >
            <Add />
        </Button>
    );
};

const MaterialEdit = (props) => {
    const translate = useTranslate();
    const history = useHistory();
    const { record: material } = useEditController(props);

    return (
        <Grid container spacing={4}>
            {material && (
                <>
                    <Grid item xs={12}>
                        <ResourceTitle>{material.name}</ResourceTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Edit {...props} title={<></>}>
                            <SimpleForm>
                                <TextField
                                    source="name"
                                    label={translate("ra.fields.material_name")}
                                />
                                <TextField
                                    source="code"
                                    label={translate("ra.fields.code")}
                                />
                                <ColorInput
                                    source="colour"
                                    label={translate("ra.fields.colour")}
                                    style={{ marginBottom: "2rem" }}
                                />
                                <ReferenceInput
                                    label={translate("ra.fields.material_type")}
                                    source="material_type_id"
                                    reference="material-types"
                                    variant="standard"
                                    sort={{ field: "name", order: "ASC" }}
                                    filterToQuery={(searchText) => ({
                                        name: searchText,
                                    })}
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                                <ReferenceManyField
                                    addLabel={true}
                                    label={translate(
                                        "ra.resources.material_names"
                                    )}
                                    reference="material-names"
                                    fullWidth={true}
                                    pagination={<MaterialNamePagination />}
                                    target="material_id"
                                    emptyText={
                                        <>
                                            <TopToolbar>
                                                <CreateButton />
                                            </TopToolbar>
                                            <EmptyList
                                                resourceName={translate(
                                                    "ra.no_resource_found.materials"
                                                )}
                                            />
                                        </>
                                    }
                                >
                                    <Datagrid
                                        rowClick={(id, basePath, record) => {
                                            return {
                                                pathname: `${basePath}/${id}`,
                                                // Here we specify the initial record for the create view
                                                state: {
                                                    record: {
                                                        material_id:
                                                            material.id,
                                                    },
                                                },
                                            };
                                        }}
                                    >
                                        <ReferenceField
                                            link={false}
                                            label={translate(
                                                "ra.fields.customer"
                                            )}
                                            source="customer_id"
                                            reference="customers"
                                            sort={{
                                                field: "name",
                                                order: "ASC",
                                            }}
                                            filterToQuery={(searchText) => ({
                                                name: searchText,
                                            })}
                                        >
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <TextField
                                            source="default_name"
                                            label={translate(
                                                "ra.fields.default_name"
                                            )}
                                        />
                                        <TextField
                                            source="custom_name"
                                            label={translate(
                                                "ra.fields.custom_name"
                                            )}
                                        />
                                    </Datagrid>
                                </ReferenceManyField>
                            </SimpleForm>
                        </Edit>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default MaterialEdit;
