import * as React from "react";
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label,
} from "recharts";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { DateTime } from "luxon";
import Box from "@material-ui/core/Box";
import { translate, useTranslate } from "react-admin";
import styles from "./WeighingTicketsOverviewChart.module.css";

const formatter = (options) => new Intl.NumberFormat("nl-NL", options);

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {},
        yAxis: {
            fontSize: "14px",
        },
    })
);

const WeighingTicketTooltip = ({ active, payload, label, years }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (active && payload && payload.length) {
        const month = DateTime.fromFormat(`${label}`, "MMM", { locale: "nl" })
            .setLocale("nl")
            .toFormat("MMMM");
        const total = payload[0].payload;
        const data = Object.entries(payload[0].payload.data).map(
            (item) => item[1]
        );
        return (
            <Card
                style={{
                    fontSize: "1rem",
                    minWidth: "350px",
                }}
            >
                <Box style={{ flex: 1 }}>
                    <CardContent
                        style={{
                            backgroundColor: "#f3f3f3",
                            backgroundImage:
                                "linear-gradient(to right, rgba(0,0,0,.02), rgba(0,0,0,0) 1rem)",
                            borderBottom: "solid 1px #e3e3e3",
                            padding: "1rem 1.5rem",
                            fontWeight: 900,
                            textAlign: "left",
                            flex: 1,
                            textTransform: "capitalize",
                        }}
                    >
                        {month}
                    </CardContent>
                </Box>
                <Box
                    style={{
                        display: "flex",
                    }}
                >
                    <CardContent
                        style={{
                            flex: 1,
                            backgroundColor: "#f3f3f3",
                            borderBottom: "solid 1px #e3e3e3",
                            padding: "1rem 1.5rem",
                            borderRight: "solid 1px #e3e3e3",
                        }}
                    >
                        <div style={{ fontWeight: 900 }}>
                            {translate("ra.statistics.tonnage")}
                        </div>
                        <div>
                            {formatter({
                                style: "unit",
                                unit: "kilogram",

                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(total.total_weight)}
                        </div>
                    </CardContent>
                    <CardContent
                        style={{
                            flex: 1,
                            backgroundColor: "#f3f3f3",
                            borderBottom: "solid 1px #e3e3e3",
                            padding: "1rem 1.5rem",
                        }}
                    >
                        <div style={{ fontWeight: 900 }}>
                            {translate("ra.statistics.revenue")}
                        </div>
                        <div>
                            {formatter({
                                style: "currency",
                                currency: "EUR",

                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(total.total_price)}
                        </div>
                    </CardContent>
                </Box>
                <Box>
                    <CardContent
                        style={{
                            flex: 1,
                            backgroundColor: "#f3f3f3",
                            borderBottom: "solid 1px #e3e3e3",
                            padding: "1rem 1.5rem",
                        }}
                    >
                        <div style={{ fontWeight: 900 }}>
                            {translate("ra.statistics.average_revenue")}
                        </div>
                        <div>
                            {formatter({
                                style: "currency",
                                currency: "EUR",

                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(total.avg_price)}
                        </div>
                    </CardContent>
                </Box>
                <Box>
                    <CardContent
                        style={{
                            padding: "1rem 1.5rem",
                            height: "200px",
                            overflow: "auto",
                        }}
                    >
                        {data.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: "1rem",
                                }}
                            >
                                <div
                                    style={{
                                        flex: "1 0 auto",
                                        flexWrap: "nowrap",
                                        paddingRight: "1rem",
                                        color: item.colour,
                                        width: "33%",
                                    }}
                                >
                                    <span
                                        style={{
                                            display: "inline-block",
                                            width: ".75rem",
                                            height: ".75rem",
                                            borderRadius: "50%",
                                            marginRight: ".5rem",
                                            background: item.colour,
                                        }}
                                    />
                                    {item.name}
                                </div>
                                <div
                                    style={{
                                        flex: "3 0 auto",
                                        flexWrap: "nowrap",
                                        width: "66%",
                                    }}
                                >
                                    {years.map((year, index) => (
                                        <div
                                            style={{
                                                color: item.colour,
                                                opacity: index === 0 ? 1 : 0.6,
                                            }}
                                            key={index}
                                        >{`${year} - ${formatter({
                                            style: "unit",
                                            unit: "kilogram",

                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(
                                            item.weight[year]
                                        )} (${formatter({
                                            style: "currency",
                                            currency: "EUR",

                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(item.price[year])})`}</div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </CardContent>
                </Box>
            </Card>
        );
    }

    return null;
};

const WeighingTicketsOverviewChart = ({
    data,
    selectedMaterials,
    selectedYears,
}) => {
    const translate = useTranslate();

    const years = [...new Set(selectedYears)];

    return data &&
        !!data.length &&
        selectedMaterials &&
        !!selectedMaterials.length &&
        selectedYears &&
        !!selectedYears.length ? (
        <ResponsiveContainer
            width={"100%"}
            height={500}
            className={styles.weighingTicketChart}
        >
            <ComposedChart
                width={"100%"}
                height={500}
                data={data}
                margin={{
                    top: 40,
                    bottom: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis dataKey="monthLabel" stroke="rgba(0, 0, 0, 0.54)" />
                <YAxis
                    yAxisId="1"
                    label={{
                        value: translate("ra.statistics.tonnage"),
                        position: "insideTop",
                        offset: -40,
                        className: styles.chartLabel,
                    }}
                    stroke="rgba(0, 0, 0, 0.54)"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) =>
                        formatter({
                            style: "unit",
                            unit: "kilogram",

                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(value)
                    }
                    width={90}
                />
                <YAxis
                    yAxisId="2"
                    label={{
                        value: translate("ra.statistics.price"),
                        position: "insideTop",
                        offset: -40,
                        className: styles.chartLabel,
                    }}
                    stroke="rgba(0, 0, 0, 0.54)"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) =>
                        formatter({
                            style: "currency",
                            currency: "EUR",

                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(value)
                    }
                    orientation="right"
                    width={90}
                />
                <Tooltip
                    wrapperStyle={{ pointerEvents: "auto" }}
                    content={<WeighingTicketTooltip years={years} />}
                    viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                    active={true}
                />
                <Legend
                    payload={[
                        ...selectedMaterials.map((material) => {
                            return {
                                value: material.name,
                                type: "bar",
                                id: material.code,
                                color: material.colour,
                            };
                        }),
                    ]}
                />
                {[
                    ...new Set(
                        data.map((item) => Object.keys(item.data)).flat()
                    ),
                ].map((materialId) => {
                    const material = data.find((item) => {
                        return Object.prototype.hasOwnProperty.call(
                            item.data,
                            materialId
                        );
                    }).data[materialId];
                    return (
                        <React.Fragment key={materialId}>
                            {years.map((year, index) => (
                                <Bar
                                    key={`${year}_${materialId}_weight`}
                                    yAxisId="1"
                                    stackId={`${index}`}
                                    barSize={20 / years.length}
                                    dataKey={`data.${materialId}.weight.${year}`}
                                    name={`${material.name} weight ${year}`}
                                    fill={material.colour}
                                    style={{
                                        opacity: index === 0 ? 1 : 0.6,
                                    }}
                                />
                            ))}

                            {years.length === 1 &&
                                years.map((year) => (
                                    <Line
                                        key={`${year}_${materialId}_price`}
                                        yAxisId="2"
                                        stackId={`${year}`}
                                        type="monotone"
                                        dataKey={`data.${materialId}.price.${year}`}
                                        name={`${material.name} price ${year}`}
                                        fill={material.colour}
                                        stroke={material.colour}
                                    />
                                ))}
                        </React.Fragment>
                    );
                })}
                )
            </ComposedChart>
        </ResponsiveContainer>
    ) : (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div>{translate("ra.dashboard.no_materials_selected")}</div>
        </div>
    );
};

export default WeighingTicketsOverviewChart;
