import { Sidebar as RaSidebar } from "react-admin";
import { useSelector } from "react-redux";
import clsx from "clsx";
import GradientPaper from "../Components/Surfaces/GradientPaper";
import styles from "./Sidebar.module.css";
import { useAppContext } from "../appProvider";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

const Sidebar = ({ children, className, ...props }) => {
    const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
    const { siteSettings: settings } = useAppContext();

    return (
        <RaSidebar>
            <GradientPaper
                {...props}
                className={clsx(className, styles.sidebar, {
                    [styles.sidebarOpen]: sidebarOpen,
                })}
            >
                <div className={styles.logo}>
                    <Link component={RouterLink} to="#">
                        <img
                            src={settings?.logoUrl}
                            alt={settings?.name}
                            className={styles.logoImg}
                        />
                        <img
                            src={settings?.logoShortUrl}
                            alt={settings?.name}
                            className={styles.logoImgSmall}
                        />
                    </Link>
                </div>
                {children}
            </GradientPaper>
        </RaSidebar>
    );
};

export default Sidebar;
