import React, { useRef, useState } from "react";
import { useShowController, useTranslate } from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Price from "../Components/Price";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import axios from "axios";
import Cookies from "js-cookie";

const InvoiceShow = (props) => {
    const translate = useTranslate();
    const history = useHistory();
    const { record: invoice } = useShowController(props);
    const contentRef = useRef();
    const [loading, setLoading] = useState(false);

    if (!invoice) return null;

    const downloadInvoicePdf = () => {
        const cookieData = Cookies.get("portal_auth");
        const auth = cookieData ? JSON.parse(cookieData) : null;
        if (!auth) return;

        setLoading(true);

        return axios
            .get(
                `${process.env.REACT_APP_DATA_PROVIDER_URI}/invoices/${invoice.id}/download`,
                {
                    responseType: "blob",
                    headers: { "X-Authorization": `Bearer ${auth.token}` },
                }
            )
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                setLoading(false);

                // create "a" HTLM element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", `Factuur-${invoice.number}.pdf`); //or any other extension
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={4} ref={contentRef}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.invoice")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={invoice.number}
                        action={
                            <>
                                {invoice.file_name && (
                                    <Button
                                        disabled={loading}
                                        color="primary"
                                        startIcon={<InsertDriveFileIcon />}
                                        onClick={downloadInvoicePdf}
                                    >
                                        PDF
                                    </Button>
                                )}
                                <ReactToPrint
                                    trigger={() => (
                                        <Button
                                            color="primary"
                                            startIcon={<PrintIcon />}
                                        >
                                            Print
                                        </Button>
                                    )}
                                    content={() => contentRef.current}
                                    pageStyle={{
                                        margin: "2rem",
                                    }}
                                />
                            </>
                        }
                    />
                    <CardContent>
                        {invoice.credit_number && (
                            <div>
                                <p>{translate("ra.fields.creditor_number")}</p>
                                <p>{invoice.credit_number}</p>
                                <p>{translate("ra.fields.date")}</p>
                                <p>{invoice.date}</p>
                            </div>
                        )}
                    </CardContent>
                    {invoice.invoice_items && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {translate("ra.fields.date")}
                                    </TableCell>
                                    <TableCell>
                                        {translate(
                                            "ra.fields.weighing_ticket_number"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {translate(
                                            "ra.fields.material_description"
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate("ra.fields.weight")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate("ra.fields.unit_price")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate("ra.fields.total_price")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoice.invoice_items.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell>
                                            {item.weighing_ticket_item ? (
                                                <Chip
                                                    label={
                                                        item
                                                            .weighing_ticket_item
                                                            ?.weighing_ticket_number
                                                    }
                                                    onClick={() =>
                                                        history.push(
                                                            `/weighing-tickets/${item.weighing_ticket_item?.weighing_ticket_id}/show`
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "N/A"
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {item.description}
                                        </TableCell>
                                        <TableCell align="right">
                                            {item.weighing_ticket_item?.weight
                                                ? `${item.weighing_ticket_item.weight} kg`
                                                : "N/A"}
                                        </TableCell>

                                        <TableCell align="right">
                                            <Price>{item.unit_price}</Price>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Price>{item.total_price}</Price>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default InvoiceShow;
