import React from "react";
import { useShowController, useTranslate } from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const WeighingTicketTitle = ({ record }) => {
    return <span>Weighing ticket {record ? `#${record.number}` : ""}</span>;
};

const WeighingTicketShow = (props) => {
    const translate = useTranslate();
    const { record: weighingTicket } = useShowController(props);
    if (!weighingTicket) return null;

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.weighing_ticket")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={weighingTicket.number} />
                    <CardContent>
                        {weighingTicket.customer && (
                            <div>
                                <p>{weighingTicket.customer?.name}</p>
                                <p>
                                    {weighingTicket.customer?.street}{" "}
                                    {weighingTicket.customer?.house_number}
                                    {
                                        weighingTicket.customer
                                            ?.house_number_addition
                                    }
                                </p>
                                <p>
                                    {weighingTicket.customer?.postcode}{" "}
                                    {weighingTicket.customer?.city}
                                </p>
                            </div>
                        )}
                        <div>
                            <p>{weighingTicket.date}</p>
                        </div>
                    </CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {translate("ra.fields.description")}
                                </TableCell>
                                <TableCell>
                                    {translate("ra.fields.weight")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weighingTicket.weighing_ticket_items?.map(
                                (item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {item.description}
                                        </TableCell>
                                        <TableCell>{item.weight} kg</TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Card>
            </Grid>
        </Grid>
    );
};

export default WeighingTicketShow;
