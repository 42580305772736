import { useSelector, useDispatch } from "react-redux";
import { AppBar, Notification, setSidebarVisibility } from "react-admin";
import { useEffect } from "react";
import clsx from "clsx";
import styles from "./Layout.module.css";
import Sidebar from "./Sidebar";
import Menu from "./Menu";
import RaakUserMenu from "./RaakUserMenu";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ProfileProvider } from "../Profile/ProfileEdit";
import { useAuthenticated } from "react-admin";
import { DataFilterProvider } from "../dataFilterProvider";

const Layout = ({ children, dashboard, logout, title }) => {
    useAuthenticated(); // redirects to login if not authenticated
    const dispatch = useDispatch();
    const theme = useTheme();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);

    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        dispatch(setSidebarVisibility(isDesktop));
    }, [isDesktop]);

    return (
        <ProfileProvider>
            <DataFilterProvider>
                <div
                    className={clsx(styles.layout, {
                        [styles.appSidebarClosed]: !open,
                    })}
                >
                    <main className={styles.appFrame}>
                        <Sidebar className={styles.appSidebar}>
                            <Menu logout={logout} hasDashboard={!!dashboard} />
                        </Sidebar>
                        <div className={styles.content}>
                            <AppBar
                                classes={{
                                    toolbar: styles.toolbar,
                                }}
                                className={styles.appBar}
                                title={title}
                                open={open}
                                logout={logout}
                                color="default"
                                userMenu={<RaakUserMenu />}
                            />
                            {children}
                        </div>
                        <Notification />
                    </main>
                </div>
            </DataFilterProvider>
        </ProfileProvider>
    );
};

export default Layout;
