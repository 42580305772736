import {
    Edit,
    NumberInput,
    SimpleForm,
    TextInput,
    useEditController,
    useMutation,
    useNotify,
    useRedirect,
    useTranslate,
} from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import React, { useCallback } from "react";
import ResourceEditToolbar from "../Components/ResourceEditToolbar";
import { Grid } from "@material-ui/core";

const MaterialTypeEdit = (props) => {
    const translate = useTranslate();
    const { record: materialType } = useEditController(props);
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const notify = useNotify();

    const save = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: "update",
                        resource: "material-types",
                        payload: { data: values, id: values.id },
                    },
                    { returnPromise: true }
                );
                notify("ra.notification.updated", {
                    messageArgs: { smart_count: 1 },
                });
                redirect("list", props.basePath);
            } catch (error) {
                notify("ra.message.invalid_form", { type: "warning" });
            }
        },
        [mutate]
    );

    return (
        <Grid container spacing={4}>
            {materialType && (
                <Grid item xs={12}>
                    <ResourceTitle>{materialType.name}</ResourceTitle>
                </Grid>
            )}
            <Grid item xs={12}>
                <Edit {...props} title={<></>}>
                    <SimpleForm toolbar={<ResourceEditToolbar />} save={save}>
                        <TextInput
                            source="name"
                            variant="standard"
                            label={translate("ra.fields.name")}
                        />
                        <NumberInput
                            options={{
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                            }}
                            min={0}
                            variant="standard"
                            source="co2_avoidance_coefficient"
                            label={translate("ra.fields.co2_coefficient")}
                        />
                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>
    );
};

export default MaterialTypeEdit;
