import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const SelectField = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input, // eslint-disable-line react/prop-types
    inputProps = {},
    label = "",
    options = [],
    ...props
}) => {
    if (!options.length) return null;
    return (
        <FormControl inputProps={inputProps} {...input} {...props}>
            <InputLabel variant="outlined">{label}</InputLabel>
            <Select
                inputProps={inputProps}
                {...input}
                {...props}
                value={input.value}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectField;
