import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import React from "react";

const CardHeaderButton = ({ url, label, color }) => (
    <Button
        to={url}
        variant="text"
        color={color}
        component={RouterLink}
        endIcon={<ArrowRightAltIcon />}
    >
        {label}
    </Button>
);

export default CardHeaderButton;
