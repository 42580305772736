import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    useTranslate,
    Pagination,
    TopToolbar,
    CreateButton,
    NumberField,
} from "react-admin";
import ResourceTitle from "../Components/ResourceTitle";
import { Card, CardHeader, Grid } from "@material-ui/core";
import React from "react";
import EmptyList from "../Components/EmptyList";
import { ColorField } from "react-admin-color-input";
import FloatField from "../Components/Fields/FloatField";

const MaterialPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const MaterialList = (props) => {
    const translate = useTranslate();
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.materials")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={translate("ra.dashboard.overview")} />
                    <List
                        {...props}
                        title={<></>}
                        perPage={25}
                        pagination={<MaterialPagination />}
                        bulkActionButtons={false}
                        exporter={false}
                        actions={false}
                        sort={{ field: "code", order: "ASC" }}
                        empty={
                            <>
                                <TopToolbar>
                                    <CreateButton />
                                </TopToolbar>
                                <EmptyList
                                    resourceName={translate(
                                        "ra.no_resource_found.materials"
                                    )}
                                />
                            </>
                        }
                    >
                        <Datagrid rowClick="edit">
                            <TextField
                                source="code"
                                label={translate("ra.fields.code")}
                            />
                            <TextField
                                source="name"
                                label={translate("ra.fields.material_name")}
                            />
                            <ReferenceField
                                label={translate("ra.fields.material_type")}
                                reference="material-types"
                                source="material_type_id"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ColorField
                                source="colour"
                                label={translate("ra.fields.colour")}
                            />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MaterialList;
