import { fetchUtils } from "react-admin";
import Cookies from "js-cookie";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const cookieData = Cookies.get("portal_auth");
    const token = cookieData ? JSON.parse(cookieData).token : null;
    if (token) {
        options.credentials = "include";
        options.headers.set("X-Authorization", `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};

export default httpClient;
