import { createContext, useContext, useLayoutEffect, useState } from "react";
import apiService from "./apiService";
import {
    useModal,
    ErrorMessage,
    LoadingSpinner,
} from "./Components/OverlayModal";
import Cookies from "js-cookie";

const APP_MODE = process?.env?.NODE_ENV;

const AppContext = createContext(null);

export const useAppContext = () => {
    return useContext(AppContext);
};

const AppProvider = ({ children }) => {
    const [siteSettings, setSiteSettings] = useState(null);

    const { setContent } = useModal();

    const domain = window.location.hostname;

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const portal = params?.portal || Cookies.get("portal_portal") || null;

    useLayoutEffect(() => {
        const getSiteSettings = async () => {
            const { data } = await apiService.getSiteSettings(domain, portal);

            if (data?.settings)
                setSiteSettings({
                    domain: data?.domain,
                    subdomains: data?.subdomains,
                    ...data?.settings,
                });
        };

        setContent(LoadingSpinner);
        getSiteSettings().catch((err) => {
            if (err.response?.status === 404) {
                setContent(
                    <ErrorMessage message="404. Portal niet gevonden" />
                );
                Cookies.remove("portal_portal");
            } else {
                setContent(<ErrorMessage />);
            }
        });
    }, []);

    // Generate dynamic favicon from site settings
    const generateFavicons = (favicon = []) => {
        if (!favicon.length) return null;

        let headTitle = document.querySelector("head");

        return favicon.forEach((item) => {
            if (!item.href) return;

            let setFavicon = document.createElement("link");
            for (const [key, value] of Object.entries(item)) {
                setFavicon.setAttribute(key, value);
            }
            headTitle.appendChild(setFavicon);
        });
    };

    // Setting website title, favicon etc.
    useLayoutEffect(() => {
        if (siteSettings) {
            document.title = siteSettings?.name;
            generateFavicons(siteSettings?.favicon);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        }
    }, [siteSettings]);

    return (
        <AppContext.Provider
            value={{
                siteSettings,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
