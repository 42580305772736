import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";

const FormattedDateField = (props) => {
    const { className, source, ...rest } = props;
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
    const value = get(record, source);
    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...rest}
        >
            {value}
        </Typography>
    );
};

FormattedDateField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default FormattedDateField;
