import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useListContext, useTranslate } from "react-admin";

const EmptyList = ({ resourceName }) => {
    const translate = useTranslate();
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="left" m={"0 3rem 2rem"}>
            <Typography paragraph>
                {translate("ra.no_resource_found.text", {
                    resource: resourceName,
                })}
            </Typography>
        </Box>
    );
};

export default EmptyList;
