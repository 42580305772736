import {
    createStyles,
    fade,
    Theme,
    makeStyles,
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import InputBase from "@material-ui/core/InputBase";
import { useEffect, useRef } from "react";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        search: {
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
        searchIcon: {
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
            flex: 1,
        },
        inputInput: {
            // padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            // transition: theme.transitions.create("width"),
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // "&::-webkit-input-placeholder": {
            //     color: "red",
            //     width: "100%",
            //     textOverflow: "ellipsis",
            // },
            // [theme.breakpoints.up("sm")]: {
            //     width: "12ch",
            //     "&:focus": {
            //         width: "20ch",
            //     },
            // },
        },
        clearButton: {
            padding: 0,
        },
        clearIcon: {},
    })
);

const SearchBar = ({ placeholder = "", value = "", onChange = () => {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.search}>
            <InputBase
                value={value}
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={(event) => onChange(event.target.value)}
            />
            <div className={classes.searchIcon}>
                {value.length === 0 ? (
                    <SearchIcon />
                ) : (
                    <IconButton
                        className={classes.clearButton}
                        onClick={() => {
                            onChange("");
                        }}
                    >
                        <CancelIcon className={classes.clearIcon} />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
