import polyglotI18nProvider from "ra-i18n-polyglot";
import { merge } from "lodash";
import dutchMessages from "ra-language-dutch";
import customLocale from "./locale/nl_nl";

const i18nProvider = polyglotI18nProvider(
    () => merge({}, dutchMessages, customLocale),
    "nl",
    { allowMissing: true, onMissingKey: (key, _, __) => key }
);

export default i18nProvider;
