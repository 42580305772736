import { getResources, MenuItemLink, useTranslate } from "react-admin";
import PortalThemeProvider from "../Theme/PortalThemeProvider";
import { makeStyles } from "@material-ui/core/styles";
import { shallowEqual, useSelector } from "react-redux";
import * as React from "react";
import classnames from "classnames";
import lodashGet from "lodash/get";
import { useGetResourceLabel } from "ra-core";
import DefaultIcon from "@material-ui/icons/ViewList";
import { Icon } from "@material-ui/core";

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
    (theme) => ({
        main: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: "0.5em",
            [theme.breakpoints.only("xs")]: {
                marginTop: 0,
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "1.5em",
            },
        },
        open: {
            width: lodashGet(theme, "menu.width", MENU_WIDTH),
        },
        closed: {
            width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
        },
    }),
    { name: "RaMenu" }
);

const Menu = (props) => {
    const {
        classes: classesOverride,
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;
    const classes = useStyles(props);
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual);
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();

    const theme = {
        overrides: {
            RaMenu: {
                open: {
                    position: "relative",
                    width: "auto",
                },
            },
            RaMenuItemLink: {
                root: {
                    fontWeight: "bold",
                    borderRadius: ".25rem",
                    padding: ".5rem .5rem",
                    marginBottom: ".25rem",
                    left: "-.5rem",
                    width: open ? "calc(100% + 1rem)" : "auto",
                },
                active: {
                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
                icon: {
                    color: "currentColor",
                    display: "flex",
                    justifyContent: "center",
                    minWidth: "auto",
                    marginRight: "1rem",
                },
            },
            MuiSvgIcon: {
                root: {
                    margin: "0 auto 0 .25rem",
                    fontSize: !open ? "2rem" : "1.5rem",
                },
            },
            MuiIcon: {
                root: {
                    fill: "currentColor",
                    width: "1.25em",
                    height: "1em",
                    margin: "0 auto 0 .25rem",
                    display: "inline-flex",
                    justifyContent: "center",
                    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    flexShrink: 0,
                    userSelect: "none",
                    fontSize: !open ? "2rem" : "1.5rem",
                    textAlign: "center",
                },
            },
        },
    };

    return (
        <PortalThemeProvider options={theme}>
            <div
                className={classnames(
                    classes.main,
                    {
                        [classes.open]: open,
                        [classes.closed]: !open,
                    },
                    className
                )}
                {...rest}
            >
                {hasDashboard && (
                    <MenuItemLink
                        to="/"
                        primaryText={translate("ra.page.dashboard")}
                        leftIcon={<Icon className="fal fa-chart-pie-alt" />}
                        exact
                        dense={dense}
                        {...props}
                    />
                )}
                {resources
                    .filter((r) => r.hasList)
                    .map((resource) => (
                        <MenuItemLink
                            key={resource.name}
                            to={{
                                pathname: `/${resource.name}`,
                                state: { _scrollToTop: true },
                            }}
                            primaryText={getResourceLabel(resource.name, 2)}
                            leftIcon={
                                resource.icon ? (
                                    <resource.icon />
                                ) : (
                                    <DefaultIcon />
                                )
                            }
                            dense={dense}
                        />
                    ))}
            </div>
        </PortalThemeProvider>
    );
};

export default Menu;
