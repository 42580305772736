import * as React from "react";
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    BarSeries,
    Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Stack } from "@devexpress/dx-react-chart";
import { Plugin } from "@devexpress/dx-react-core";

const formatter = (options) => new Intl.NumberFormat("nl-NL", options);

const DecimalLabel = ({ text, ...restProps }) => {
    return (
        <ValueAxis.Label
            text={formatter({
                style: "currency",
                currency: "EUR",

                minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }).format(Number(text.split(",").join("")))}
            style={{
                fontSize: "14px",
            }}
            {...restProps}
        />
    );
};

const TextLabel = ({ text, ...restProps }) => {
    return (
        <ArgumentAxis.Label
            text={text.toUpperCase()}
            style={{
                fontSize: "14px",
            }}
            {...restProps}
        />
    );
};

const TooltipContent = ({ text, ...props }) => (
    <Tooltip.Content
        text={formatter({
            style: "currency",
            currency: "EUR",

            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(text)}
        {...props}
    />
);

const DashboardOverviewChart = ({ data, colors = ["#607787", "#8fa2af"] }) => {
    return (
        <Chart data={data} height={400}>
            <ArgumentAxis labelComponent={TextLabel} />
            <ValueAxis labelComponent={DecimalLabel} />
            <Plugin>
                {[
                    ...new Set(
                        data
                            .map((item) => Object.keys(item))
                            .flat()
                            .filter((key) => key !== "date")
                            .sort((a, b) => b - a)
                    ),
                ].map((key, index) => {
                    return (
                        <BarSeries
                            name={key}
                            color={colors[index]}
                            key={index}
                            valueField={key}
                            argumentField="date"
                            barWidth={"1"}
                        />
                    );
                })}
            </Plugin>
            <EventTracker />
            <Tooltip contentComponent={TooltipContent} />
            <Stack />
        </Chart>
    );
};

export default DashboardOverviewChart;
