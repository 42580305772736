import {
    Datagrid,
    Filter,
    List,
    Pagination,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import ResourceTitle from "../Components/ResourceTitle";
import EmptyList from "../Components/EmptyList";
import FormattedDateField from "../Components/Fields/FormattedDateField";
import CurrencyField from "../Components/Fields/CurrencyField";

const CustomerPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const CustomerList = ({ permissions, ...props }) => {
    const translate = useTranslate();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.customers")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={translate("ra.dashboard.overview")} />
                    <List
                        {...props}
                        sort={{ field: "name", order: "DESC" }}
                        title={<></>}
                        perPage={25}
                        pagination={<CustomerPagination />}
                        bulkActionButtons={false}
                        actions={null}
                        exporter={null}
                        filters={
                            <Filter>
                                <TextInput
                                    source="name"
                                    label="Zoek op naam"
                                    alwaysOn
                                />
                            </Filter>
                        }
                        empty={
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.customers"
                                )}
                            />
                        }
                    >
                        <Datagrid rowClick="show" size="medium">
                            <TextField
                                source="name"
                                label={translate("ra.fields.name")}
                            />
                            <TextField
                                source="credit_number"
                                label={translate("ra.fields.creditor_number")}
                            />
                            <TextField
                                source="activation_code"
                                variant="standard"
                                label={translate("ra.fields.activation_code")}
                            />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CustomerList;
