import React from "react";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { Grid, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import AuthLayout from "../Layout/AuthLayout";
import { useAppContext } from "../appProvider";
import Aside from "../Components/Aside";

const useStyles = makeStyles((theme) => ({
    input: {
        ...theme.components?.PortalInput,
        width: "100%",
        "&:first-child": {
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("md")]: {
                marginTop: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
                marginTop: theme.spacing(5),
            },
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
    linkSecondary: {
        ...theme.components?.PortalLinkSecondary,
    },
    button: {
        ...theme.components?.PortalButton,
    },
}));

const RegisterPage = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const { siteSettings: settings } = useAppContext();

    const content = (
        <>
            <Box mt={4} mb={4}>
                <Typography variant="body1" color="textSecondary">
                    {translate("ra.auth.register.content")}
                </Typography>
            </Box>
            <Grid container alignItems="center" justifyContent="space-between">
                {settings?.accountRegistrationMail && (
                    <Grid item xs={10}>
                        <Button
                            href={settings.accountRegistrationMail}
                            className={classes.button}
                        >
                            {translate("ra.auth.register.button")}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={2}>
                    <Link
                        component={RouterLink}
                        to="/login"
                        className={classes.linkSecondary}
                    >
                        {translate("ra.auth.register.back")}
                    </Link>
                </Grid>
            </Grid>
        </>
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.registerPageImage}
                    video={settings?.registerPageVideo}
                />
            }
            title={translate("ra.auth.register.title")}
        />
    );
};

export default RegisterPage;
