import { useRecordContext, FunctionField } from "react-admin";
import get from "lodash/get";

const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",

    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const CurrencyField = (props) => {
    const { className, source, ...rest } = props;
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
    const value = get(record, source);
    return (
        <FunctionField
            className={className}
            {...rest}
            render={(record) => formatter.format(value / 100)}
        />
    );
};

export default CurrencyField;
