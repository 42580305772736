import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Icon,
    Popover,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CarouselSelect from "./CarouselSelect";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    RaakCheckbox: {
        icon: {
            borderWidth: 2,
        },
    },
});

const PeriodSelector = ({
    onChange = () => {},
    range = [],
    popupButtonLabel = "Periode",
    firstButtonLabel = "Periode",
    secondButtonLabel = "Periode",
    firstPeriod = "",
    secondPeriod = "",
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [showSecondPeriod, setShowSecondPeriod] = useState(() => {
        return firstPeriod !== secondPeriod;
    });
    const [first, setFirst] = useState(firstPeriod);
    const [second, setSecond] = useState(secondPeriod);

    const buttonRef = useRef(null);
    const translate = useTranslate();
    const classes = useStyles();

    const firstHandler = (date) => {
        setFirst(date);
    };

    const secondHandler = (date) => {
        setSecond(date);
    };

    const handleCheckboxChange = (event) => {
        setShowSecondPeriod(event.target.checked);
        if (!event.target.checked) {
            setSecond("");
        }
    };

    useEffect(() => {
        onChange(first, second);
    }, [first, second]);

    return (
        <>
            {!!range.length && (
                <>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={() => setShowPopup(!showPopup)}
                        ref={buttonRef}
                    >
                        {popupButtonLabel}
                        <Icon style={{ width: ".75em" }}>arrow_drop_down</Icon>
                    </Button>

                    <Backdrop
                        open={showPopup}
                        onClose={() => setShowPopup(false)}
                        style={{ zIndex: 9999 }}
                    >
                        <Popover
                            open={showPopup}
                            anchorEl={buttonRef.current}
                            onClose={() => setShowPopup(false)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            style={{ marginTop: "1rem" }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "2rem",
                                    borderBottom: "1px solid #E3E3E3",
                                }}
                            >
                                <label
                                    style={{
                                        flex: 1,
                                        marginRight: "1rem",
                                        fontWeight: 700,
                                    }}
                                >
                                    {firstButtonLabel}
                                </label>
                                <CarouselSelect
                                    data={range}
                                    initialValue={firstPeriod}
                                    onChange={firstHandler}
                                />
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1rem 2rem",
                                }}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showSecondPeriod}
                                                onChange={(event) =>
                                                    handleCheckboxChange(event)
                                                }
                                            />
                                        }
                                        label={translate(
                                            "ra.actions.compare_to_other_period"
                                        )}
                                    />
                                </FormGroup>
                            </Box>
                            {showSecondPeriod && (
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "1rem 2rem 2rem",
                                    }}
                                >
                                    <label
                                        style={{
                                            flex: 1,
                                            marginRight: "1rem",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {secondButtonLabel}
                                    </label>
                                    <CarouselSelect
                                        data={range}
                                        initialValue={secondPeriod}
                                        onChange={secondHandler}
                                    />
                                </Box>
                            )}
                        </Popover>
                    </Backdrop>
                </>
            )}
        </>
    );
};

export default PeriodSelector;
