import React, { useEffect, useState, useCallback } from "react";
import apiService from "../apiService";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import WeighingTicketsOverviewChart from "../Components/WeighingTicketsOverviewChart";
import { useTranslate } from "react-admin";
import PeriodSelector from "../Components/PeriodSelector";
import debounce from "lodash.debounce";
import { DateTime, Interval } from "luxon";
import { merge, range } from "lodash";
import MaterialSelector from "../Components/MaterialSelector";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { useDataFilterContext } from "../dataFilterProvider";

const useStyles = makeStyles((theme) => ({
    action: {
        margin: 0,
    },
}));

const WeighingTicketChart = () => {
    const {
        selectedMaterials,
        setSelectedMaterials,
        selectedYears,
        setSelectedYears,
    } = useDataFilterContext();
    const [overviewData, setOverviewData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [periodRange, setPeriodRange] = useState([]);
    const [materialCategories, setMaterialCategories] = useState([]);

    const translate = useTranslate();
    const classes = useStyles();

    const getData = (params) => {
        apiService.getWeighingTicketsData(params).then((data) => {
            setOverviewData(data);
        });
    };

    const transformOverviewData = (data) => {
        let chartData = [...range(1, 13)]
            .map((month) => ({
                month,
                monthLabel: DateTime.fromFormat(`${month}`, "M")
                    .setLocale("nl")
                    .toFormat("MMM")
                    .toLocaleUpperCase("nl"),
                total_price: 0,
                total_weight: 0,
                avg_price: 0,
            }))
            .map((item) => {
                // insert default zero values for all selected materials
                const data = selectedMaterials.reduce((acc, material) => {
                    return {
                        ...acc,
                        [material.id]: {
                            code: material.code,
                            name: material.name,
                            colour: material.colour,
                            weight: Object.assign(
                                {},
                                [
                                    ...new Set(
                                        [
                                            selectedYears.first,
                                            selectedYears.second,
                                        ].filter(Boolean)
                                    ),
                                ].reduce((acc, year) => {
                                    return { ...acc, [year]: 0 };
                                }, {})
                            ),
                            price: Object.assign(
                                {},
                                [
                                    ...new Set(
                                        [
                                            selectedYears.first,
                                            selectedYears.second,
                                        ].filter(Boolean)
                                    ),
                                ].reduce((acc, year) => {
                                    return { ...acc, [year]: 0 };
                                }, {})
                            ),
                        },
                    };
                }, {});
                return { ...item, data: data };
            });

        // Iterate over api data and merge prices
        chartData = chartData.map((item) => {
            const filteredData = data[0]
                .filter((obj) => obj.month == item.month)
                .reduce((acc, filteredItem) => {
                    return {
                        ...acc,
                        [filteredItem.material_id]: {
                            ...acc[filteredItem.material_id],
                            price: {
                                ...acc[filteredItem.material_id]?.price,
                                [filteredItem.year]: filteredItem.price / 100,
                            },
                        },
                    };
                }, {});
            return {
                ...item,
                data: merge(item.data, filteredData),
            };
        });

        // Iterate over api data and merge weights
        chartData = chartData.map((item) => {
            const filteredData = data[1]
                .filter((obj) => obj.month == item.month)
                .reduce((acc, filteredItem) => {
                    return {
                        ...acc,
                        [filteredItem.material_id]: {
                            ...acc[filteredItem.material_id],
                            weight: {
                                ...acc[filteredItem.material_id]?.weight,
                                [filteredItem.year]: filteredItem.weight,
                            },
                        },
                    };
                }, {});
            return {
                ...item,
                data: merge(item.data, filteredData),
            };
        });

        // Iterate over api data and merge statistics data
        chartData = chartData.map((item) => {
            const filteredData = data[2]
                .filter((obj) => obj.month == item.month)
                .reduce((acc, filteredItem) => {
                    return {
                        ...acc,
                        total_weight: filteredItem.total_weight,
                        total_price: filteredItem.total_price / 100,
                        avg_price: filteredItem.avg_price / 100,
                    };
                }, {});
            return {
                ...item,
                ...filteredData,
            };
        });

        setChartData(chartData);
    };

    // Debounce the getResults function call
    const debouncedGetData = useCallback(
        debounce((params) => getData(params), 500),
        []
    );

    const getMaterialCategories = (params) => {
        apiService.getMaterialCategoriesData(params).then((res) => {
            if (res.status === 200) setMaterialCategories(res.data);
        });
    };

    const getPeriodRange = () => {
        return apiService.getWeighingTicketDateRange().then((res) => {
            if (res.status === 200) {
                let interval = Interval.fromDateTimes(
                    DateTime.fromFormat(res.data.start, "yyyy"),
                    DateTime.fromFormat(res.data.end, "yyyy")
                );
                const values = [];
                let cursor = interval.start.startOf("year");
                while (cursor <= interval.end.endOf("year")) {
                    values.push({
                        label: cursor.year.toString(),
                        value: cursor.toFormat("yyyy"),
                    });
                    cursor = cursor.plus({ year: 1 });
                }
                setPeriodRange(values);
            }
        });
    };

    const handleChangePeriod = (first, second) => {
        setSelectedYears({
            first: first,
            second: second,
        });
    };

    useEffect(() => {
        if (
            !!periodRange.length &&
            !selectedYears.first &&
            !selectedYears.second
        ) {
            setSelectedYears({
                first: periodRange[periodRange.length - 1].value,
                second: periodRange[periodRange.length - 1].value,
            });
        }
    }, [periodRange]);

    useEffect(() => {
        if (!selectedMaterials.length) {
            const topCategories = [...materialCategories]
                .sort((a, b) => b.hits - a.hits)
                .slice(0, 3);
            setSelectedMaterials(topCategories);
        }
    }, [materialCategories]);

    useEffect(() => {
        getMaterialCategories();
        getPeriodRange();
    }, []);

    useEffect(() => {
        if (
            (selectedYears.first || selectedYears.second) &&
            !!selectedMaterials.length
        ) {
            const params = {
                years: [selectedYears.first, selectedYears.second].filter(
                    Boolean
                ),
                materials: selectedMaterials.map((type) => type.id).join(","),
            };
            return debouncedGetData(params);
        }
        return setChartData(null);
    }, [selectedMaterials, selectedYears]);

    useEffect(() => {
        if (overviewData) {
            transformOverviewData(overviewData);
        }
    }, [overviewData]);

    return (
        <Card style={{ display: "flex", width: "100%", minHeight: "400px" }}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <CardHeader
                    classes={{ action: classes.action }}
                    title={translate("ra.dashboard.overview")}
                    action={
                        <>
                            {!!periodRange.length && (
                                <span style={{ marginRight: "1rem" }}>
                                    <PeriodSelector
                                        range={periodRange}
                                        firstPeriod={selectedYears.first}
                                        secondPeriod={selectedYears.second}
                                        onChange={handleChangePeriod}
                                    />
                                </span>
                            )}
                            <MaterialSelector
                                style={{ marginLeft: "1rem" }}
                                options={materialCategories ?? []}
                                value={selectedMaterials ?? []}
                                onChange={(value) => {
                                    setSelectedMaterials([...value]);
                                }}
                            />
                        </>
                    }
                />
                <CardContent
                    style={{
                        paddingBottom: 0,
                        flex: 1,
                        minHeight: "30rem",
                    }}
                >
                    <WeighingTicketsOverviewChart
                        data={chartData}
                        selectedMaterials={selectedMaterials}
                        selectedYears={[
                            selectedYears.first,
                            selectedYears.second,
                        ].filter(Boolean)}
                        materialCategories={materialCategories}
                    />
                </CardContent>
            </Box>
        </Card>
    );
};

export default WeighingTicketChart;
