import DashboardCustomer from "./DashboardCustomer";
import DashboardAdmin from "./DashboardAdmin";

const Dashboard = ({ permissions }) => {
    switch (permissions) {
        case "admin":
            return <DashboardAdmin />;
        case "employee":
        case "manager":
            return <DashboardCustomer />;
    }

    return <></>;
};

export default Dashboard;
