import jsonServerProvider from "ra-data-json-server";
import httpClient from "./httpClient";
import authProvider from "./authProvider";

const PROVIDER_URI = process.env.REACT_APP_DATA_PROVIDER_URI || null;

const dataProvider = (dataProvider) => ({
    ...dataProvider,
    getList: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.getList(resource, params);
    },
    getOne: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.getOne(resource, params);
    },
    getMany: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.getMany(resource, params);
    },
    getManyReference: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.getManyReference(resource, params);
    },
    update: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.update(resource, params);
    },
    create: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.create(resource, params);
    },
    delete: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.delete(resource, params);
    },
    deleteMany: async (resource, params) => {
        if (resource === "users") {
            const permissions = await authProvider.getPermissions();
            switch (permissions) {
                case "manager":
                    resource = `manager/${resource}`;
                    break;
                case "admin":
                    resource = `admin/${resource}`;
                    break;
            }
        }
        return dataProvider.deleteMany(resource, params);
    },
});

export default dataProvider(jsonServerProvider(PROVIDER_URI, httpClient));
